import { Col, Skeleton, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import apiPath from "../../constants/apiPath";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { UserWeeklyChart } from "../../components/_Weeklychart";
import SectionWrapper from "../../components/SectionWrapper";
import moment from "moment";

const dummy = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Dataset 1",
      data: [
        null,
        null,
        null,
        null,
        null,
        16000,
        null,
        5000,
        null,
        null,
        null,
        null,
      ], // Starting from June
      borderColor: "#CE9944",
      tension: 0.1,
      spanGaps: true, // Add this option
    },
    {
      label: "Dataset 2",
      data: [
        null,
        null,
        null,
        null,
        null,
        7000,
        null,
        8000,
        null,
        null,
        null,
        null,
      ], // Starting from June
      borderColor: "#CE9944",
      backgroundColor: "#CE9944",
      
      spanGaps: true, // Add this option
      // yAxisID: 'y1',
    },
  ],
};

const getLastSixWeeks = () => {
  const lastSixWeeks = [];
  for (let i = 5; i >= 0; i--) {
    const startDate = moment().subtract(i, "weeks").startOf("isoWeek");
    const weekNumber = startDate.isoWeek();
    lastSixWeeks.push({
      startDate: startDate.format("YYYY-MM-DD"),
      weekNumber: weekNumber,
    });
  }
  return lastSixWeeks;
};

const WeeklyChartSection = () => {
  const { country } = useAppContext();
  const { request } = useRequest();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [years, setYears] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });

  useEffect(() => {
    setLoading(true);
    // fetchData({ ...pagination, current: 1 });
    getFilters();
  }, [refresh, filter, country.country_id]);

  const getFilters = () => {
    request({
      url: `${apiPath.dashboard}/sales/filters`,
      method: "GET",
      onSuccess: (res) => {
        if (res.status) {
          const { data, months, years } = res;
          setYears(years);
        }
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const fetchData = () => {
    request({
      url: `${apiPath.dashboard}/graph?period=weekly${
        filter.year ? `&year=${filter.year}` : ``
      }`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        console.log(data, "weekly");
        if (status) {
          setData(data);
          // const arr = [0, 0, 0, 0, 0, 0];
          // data.map(({ interval, count }) => {
          //   const findIndex = lastSixWeeks.findIndex(
          //     ({ weekNumber }) => weekNumber == parseInt(interval)
          //   );
          //   if (findIndex != -1) {
          //     arr[findIndex] = count;
          //   } else {
          //     arr[findIndex] = 0;
          //   }
          // });
          // console.log(arr, "llll");
          // setData(arr);
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <>
      {loading ? [1, 2].map((item) => <Skeleton active key={item} />)
        : <>
          <Col xs={24} xl={12} lg={24} className="mb-24">
            <SectionWrapper
              cardHeading={<div className="info-tooltip-div">{lang("Number of Users")} <Tooltip title={lang("Total number of active users registered on the platform.")}><span><i class="fas fa-info-circle"></i></span></Tooltip>  </div> }
              extra={
                <>
                  <div className="w-100 text-head_right_cont">
                    <div className="role-wrap"></div>
                  </div>
                </>
              }
            >
              <UserWeeklyChart data={data?.user || []} />
            </SectionWrapper>
          </Col>

          <Col xs={24} xl={12} lg={24} className="mb-24">
            <SectionWrapper
              cardHeading={<div className="info-tooltip-div">{lang("Number of Active Users ")} <Tooltip title={lang("Total number of users registered on the platform.")}><span><i class="fas fa-info-circle"></i></span></Tooltip>  </div> }
              extra={
                <>
                  <div className="w-100 text-head_right_cont">
                    <div className="role-wrap"></div>
                  </div>
                </>
              }
            >
              <UserWeeklyChart data={data?.activeUser || []} />
            </SectionWrapper>
          </Col>

          <Col xs={24} xl={12} lg={24} className="mb-24">
            <SectionWrapper
              cardHeading={<div className="info-tooltip-div">{lang("Number of Service Provider")} <Tooltip title={lang("Total service providers currently registered.")}><span><i class="fas fa-info-circle"></i></span></Tooltip>  </div> }

              extra={
                <>
                  <div className="w-100 text-head_right_cont">
                    <div className="role-wrap"></div>
                  </div>
                </>
              }
            >
              <UserWeeklyChart data={data?.provider || []} />
            </SectionWrapper>
          </Col>

          <Col xs={24} xl={12} lg={24} className="mb-24">
            <SectionWrapper
              cardHeading={<div className="info-tooltip-div">{lang("Number of Categories")} <Tooltip title={lang("Total number of service categories available.")}><span><i class="fas fa-info-circle"></i></span></Tooltip>  </div> }

              extra={
                <>
                  <div className="w-100 text-head_right_cont">
                    <div className="role-wrap"></div>
                  </div>
                </>
              }
            >
              <UserWeeklyChart data={data?.categories || []} />
            </SectionWrapper>
          </Col>

          <Col xs={24} xl={12} lg={24} className="mb-24">
            <SectionWrapper
              cardHeading={<div className="info-tooltip-div">{lang("Number of Created Events")} <Tooltip title={lang("Total events created by users on the platform.")}><span><i class="fas fa-info-circle"></i></span></Tooltip>  </div> }

              extra={
                <>
                  <div className="w-100 text-head_right_cont">
                    <div className="role-wrap"></div>
                  </div>
                </>
              }
            >
              <UserWeeklyChart data={data?.events || []} />
            </SectionWrapper>
          </Col>

          <Col xs={24} xl={12} lg={24} className="mb-24">
            <SectionWrapper
              // cardHeading={lang("Number of Services")}
              cardHeading={<div className="info-tooltip-div">{lang("Number of Services")} <Tooltip title={lang("Total number of services offered by providers.")}><span><i class="fas fa-info-circle"></i></span></Tooltip>  </div> }

              extra={
                <>
                  <div className="w-100 text-head_right_cont">
                    <div className="role-wrap"></div>
                  </div>
                </>
              }
            >
              <UserWeeklyChart data={data?.services || []} />
            </SectionWrapper>
          </Col>

          <Col xs={24} xl={12} lg={24} className="mb-24">
            <SectionWrapper
              // cardHeading={lang("Number of Quotation")}
              cardHeading={<div className="info-tooltip-div">{lang("Number of Quotation")} <Tooltip title={lang("Quotations requested by users for various services.")}><span><i class="fas fa-info-circle"></i></span></Tooltip>  </div> }

              extra={
                <>
                  <div className="w-100 text-head_right_cont">
                    <div className="role-wrap"></div>
                  </div>
                </>
              }
            >
              <UserWeeklyChart data={data?.quotation || []} />
            </SectionWrapper>
          </Col>

          <Col xs={24} xl={12} lg={24} className="mb-24">
            <SectionWrapper
              // cardHeading={lang("Number of Pending Quotation")}
              cardHeading={<div className="info-tooltip-div">{lang("Number of Pending Quotation")} <Tooltip title={lang("Quotations that have been not replied to by service providers.")}><span><i class="fas fa-info-circle"></i></span></Tooltip>  </div> }

              extra={
                <>
                  <div className="w-100 text-head_right_cont">
                    <div className="role-wrap"></div>
                  </div>
                </>
              }
            >
              <UserWeeklyChart data={data?.pendingQuotation || []} />
            </SectionWrapper>
          </Col>

          <Col xs={24} xl={12} lg={24} className="mb-24">
            <SectionWrapper
              cardHeading={<div className="info-tooltip-div">{lang("Number of Support Cases")} <Tooltip title={lang("Total support cases raised by users.")}><span><i class="fas fa-info-circle"></i></span></Tooltip>  </div> }

              // cardHeading={lang("Number of Support Cases")}
              extra={
                <>
                  <div className="w-100 text-head_right_cont">
                    <div className="role-wrap"></div>
                  </div>
                </>
              }
            >
              <UserWeeklyChart data={data?.supportCases || []} />
            </SectionWrapper>
          </Col>

          {/* <Col xs={24} xl={12} lg={24} className="mb-24">
            <SectionWrapper
              cardHeading={lang("Account Manager Performance")}
              extra={
                <>
                  <div className="w-100 text-head_right_cont">
                    <div className="role-wrap"></div>
                  </div>
                </>
              }
            >
              <UserWeeklyChart data={data?.accPerformance || []} />
            </SectionWrapper>
          </Col> */}

          {/* <Col xs={24} xl={12} lg={24} className="mb-24">
            <SectionWrapper
              cardHeading={lang("Service providers OnBoardings")}
              extra={
                <>
                  <div className="w-100 text-head_right_cont">
                    <div className="role-wrap"></div>
                  </div>
                </>
              }
            >
              <UserWeeklyChart data={data?.Onboardings || []} />
            </SectionWrapper>
          </Col> */}

          <Col xs={24} xl={12} lg={24} className="mb-24">
            <SectionWrapper
              // cardHeading={lang("Number of Visitor's")}
              cardHeading={<div className="info-tooltip-div">{lang("Number of Visitor's")} <Tooltip title={lang("Total visits recorded on the platform.")}><span><i class="fas fa-info-circle"></i></span></Tooltip>  </div> }

              extra={
                <>
                  <div className="w-100 text-head_right_cont">
                    <div className="role-wrap"></div>
                  </div>
                </>
              }
            >
              <UserWeeklyChart data={data?.totalVisitors || []} />
            </SectionWrapper>
          </Col>
          <Col xs={24} xl={12} lg={24} className="mb-24">
            <SectionWrapper
              // cardHeading={lang("Number of Active Visitor's")}
              cardHeading={<div className="info-tooltip-div">{lang("Number of Active Visitor's")} <Tooltip title={lang("Currently active user visits on the platform.")}><span><i class="fas fa-info-circle"></i></span></Tooltip>  </div> }

              extra={
                <>
                  <div className="w-100 text-head_right_cont">
                    <div className="role-wrap"></div>
                  </div>
                </>
              }
            >
              <UserWeeklyChart data={data?.activeVisitors || []} />
            </SectionWrapper>
          </Col>
        </>
      }
    </>
  );
};

export default WeeklyChartSection;
