import { Image, Menu, Modal, Skeleton } from "antd";
import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Logo from "../../assets/images/Logo.svg";
import Bio from "../../assets/images/side_nav/bi_collection.svg";
import Blog from "../../assets/images/side_nav/blog.svg";
import Cath from "../../assets/images/side_nav/cath.svg";
import Chat from "../../assets/images/side_nav/chat.svg";
import Cms from "../../assets/images/side_nav/cms.svg";
import Coust from "../../assets/images/side_nav/customer.svg";
import Dash from "../../assets/images/side_nav/dash.svg";
import Disk from "../../assets/images/side_nav/discount.svg";
import File from "../../assets/images/side_nav/file.svg";
import Log from "../../assets/images/side_nav/log.svg";
import Bell from "../../assets/images/side_nav/notification.svg";
import Order from "../../assets/images/side_nav/order.svg";
import Rest from "../../assets/images/side_nav/resturant.svg";
import Star from "../../assets/images/side_nav/star.svg";
import Sub from "../../assets/images/side_nav/sub.svg";
import LogoutNewImg from "../../assets/images/logout (2).png";
import Service from "../../assets/images/side_nav/sub.svg";
import User from "../../assets/images/side_nav/user.svg";
import { AuthContext } from "../../context/AuthContext";
import lang from "../../helper/langHelper";
import DeleteModal from "../DeleteModal";
import { useAppContext } from "../../context/AppContext";
import moment, { isMoment } from "moment";
import { Severty, ShowToast } from "../../helper/toast";

export const countryWithAreas = [
  "646b2e0f46865f1f65565346", //Palestine
];

export const menuItems = [
  {
    key: "dashboard-management",
    path: "/dashboard",
    icon: Dash,
    label: lang("Dashboard"),
    isShow: true,
  },
  {
    key: "role-manager",
    path: "/role",
    icon: Sub,
    label: `${lang("Role")} ${lang("Management")}`,
  },
  {
    key: "sub-admin-manager",
    path: "/sub-admin",
    icon: Sub,
    label: `${lang("Sub Admin")} ${lang("Management")}`,
  },
  {
    key: "customer-manager",
    label: `${lang("Customer")} ${lang("Management")}`,
    icon: Coust, //
    path: "/customer",
  },
  {
    key: "profile-manager",
    label: `${lang("SP Profile")} ${lang("Management")}`,
    icon: Service,
    path: "/profile",
  },
  {
    key: "category-management",
    label: `${lang("Category")} ${lang("Management")}`,
    icon: Cath,
    path: "/category",
  },
  {
    key: "sub-category-management",
    label: `${lang("Sub-Category")} ${lang("Management")}`,
    icon: Bio,
    path: "/sub-category",
  },
  {
    key: "service-manager",
    label: `${lang("Service")} ${lang("Management")}`,
    icon: Order,
    path: "/service",
  },
  {
    key: "attribute-manager",
    label: `${lang("Service Attribute")} ${lang("Management")}`,
    icon: User,
    path: "/attributes",
  },

  {
    key: "provider-manager",
    label: `${lang("Service Provider")} ${lang("Management")}`,
    icon: Service,
    path: "/service-provider",
  },

  {
    key: "event-manager",
    path: "/event-type",
    icon: Chat,
    label: `${lang("Event Type")} ${lang("Management")}`,
  },

  // {
  //   key: "quote-template-manager",
  //   path: "/quote-templates",
  //   icon: Disk,
  //   label: `${lang("Quote Template")} ${lang("Management")}`,
  // },
  {
    key: "quotation-request",
    path: "/quotation-request",
    icon: File,
    label: `${lang("Quote")} ${lang("Management")}`,
  },
  {
    key: "cms-manager",
    path: "/cms",
    icon: Cms,
    label: `${lang("CMS")} ${lang("Management")}`,
  },
  {
    key: "blog-manager",
    path: "/blog",
    icon: Blog,
    label: `${lang("Blog")} ${lang("Management")}`,
  },
  {
    key: "testimonial-manager",
    path: "/testimonial",
    icon: Blog,
    label: `${lang("Testimonial")} ${lang("Management")}`,
  },
  {
    key: "report-manager",
    path: "/reports",
    icon: Chat,
    label: `${lang("Reports")}`,
  },
  {
    key: "support-manager",
    path: "/support",
    icon: Chat,
    label: `${lang("Support")}`,
  },
  // {
  //   key: "delivery-manager",
  //   path: "/delivery",
  //   icon: Blog,
  //   label: `${lang("Delivery")} ${lang("History")}`,
  // },
  // {
  //   key: "discount-manager",
  //   path: "/discount",
  //   icon: Disk,
  //   label: `${lang("Discount")} ${lang("Management")}`,
  // },
  // {
  //   key: "rating-manager",
  //   path: "/ratings",
  //   icon: Star,
  //   label: `${lang("Rating and Reviews")}`,
  // },
  {
    key: "Notifications",
    path: "/notification",
    icon: Bell,
    label: `${lang("Notifications")}`,
  },

  // {
  //   key: "collector-manager",
  //   path: "/collector",
  //   icon: Bio,
  //   label: `${lang("Collection")} ${lang("Management")}`,
  // },
  {
    key: "city-manager",
    path: "/city",
    icon: Disk,
    label: `${lang("City")} ${lang("Management")}`,
  },
  {
    key: "invitation-template-manager",
    path: "/invitation-template-manager",
    icon: Disk,
    label: lang("Invitation Templates"),
  },

  {
    key: "email-template-manager",
    path: "/email-templates",
    icon: Disk,
    label: `${lang("Email Template")} ${lang("Management")}`,
  },
];

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const { userProfile, logout, setUserProfile } = useContext(AuthContext);
  const { country } = useAppContext();

  const [collapsed, setCollapsed] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuLinks, setMenuLinks] = useState([]);
  const [menuMode, setMenuMode] = useState("vertical");
  const [currentDateTime, setCurrentDateTime] = useState(moment());

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  const showDeleteConfirm = (record) => {
    setIsLogoutModalVisible(true);
    // logout();
  };

  const renderTitle = (item) => {
    return (
      <>
        <Image preview={false} src={item.icon} />
        <span className="label">{item.label}</span>
      </>
    );
  };

  useEffect(() => {
    setLoading(true);
    if (!userProfile) return;
    if (userProfile.type == "Admin") {
      const items =
        country &&
        country?.country_id &&
        countryWithAreas.includes(country?.country_id)
          ? [...menuItems]
          : menuItems;

      setMenuLinks(items);
      setLoading(false);
      return;
    }

    const items =
      country &&
      country?.country_id &&
      countryWithAreas.includes(country?.country_id)
        ? [...menuItems]
        : menuItems;

    const newArray = items.filter((item) => {
      if (item.isShow) {
        return true;
      } else {
        return userProfile?.role_id?.permission?.includes(item.key);
      }
    });

    const links = newArray.filter((item) => {
      if (item?.children?.length) {
        return true;
      } else if (!item?.children) {
        return true;
      } else {
        return false;
      }
    });

    setMenuLinks(links);
    setLoading(false);
    setRefresh((x) => !x);
  }, [userProfile, country]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMenuMode("inline");
      } else {
        setMenuMode("vertical");
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {loading ? (
        [1, 2, 3, 4, 5, 6].map((item) => <Skeleton active key={item} />)
      ) : (
        <>
          <div className="brand-logo">
            <NavLink to="" className="imgOuter">
              <img className="" src={Logo} alt="" />
            </NavLink>
          </div>
          <Menu inlineCollapsed={false} mode={menuMode} className="sideNavMain">
            {menuLinks.map((item) => {
              if (item.children) {
                return (
                  <>
                    <Menu.SubMenu
                      key={item.key}
                      title={
                        <>
                          <span className="icon">
                            <Image preview={false} src={item.icon} />
                          </span>
                          <span className="label">{item.label}</span>
                        </>
                      }
                    >
                      {item.children.map((child) => (
                        <Menu.Item key={child.key}>
                          <NavLink to={child.path}>{child.label}</NavLink>
                        </Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  </>
                );
              }

              return (
                <Menu.Item key={item.key}>
                  <NavLink to={item.path}>{renderTitle(item)}</NavLink>
                </Menu.Item>
              );
            })}

            <Menu.Item onClick={showDeleteConfirm}>
              <NavLink to={"#"}>
                <>
                  <Image preview={false} src={Log} />
                  <span className="label">{lang("Logout")}</span>
                </>
              </NavLink>
            </Menu.Item>
          </Menu>
        </>
      )}
      {isLogoutModalVisible && (
        <DeleteModal
          title={lang("Logout")}
          subtitle={lang(`Are you sure you want to logout?`)}
          show={isLogoutModalVisible}
          hide={() => {
            setIsLogoutModalVisible(false);
          }}
          onOk={async () => {
            setIsLogoutModalVisible(false);
            try {
              await logout();
            } catch (error) {
              console.error("Logout error:", error);
              ShowToast("Logout failed", Severty.ERROR);
            }
          }}
        >
          <div className="main-new-logout-class-imagehh">
            <img src={LogoutNewImg} alt="Logout icon" />
          </div>
        </DeleteModal>
      )}
    </>
  );
}

export default Sidenav;
