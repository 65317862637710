import {
  Col,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Checkbox,
  Button,
  Space,
  InputNumber,
} from "antd";
import React, { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import notfound from "../../assets/images/not_found.png";
import SingleImageUpload from "../../components/SingleImageUpload";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import apiPath from "../../constants/apiPath";
import { duration } from "moment";
import { attributeOption } from "../../constants/var";

const AddForm = ({ api, show, hide, data, refresh, paramsIds }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const { country } = useAppContext();
  const [showOptions, setShowOptions] = useState(false);
  const [showPackage, setShowPackage] = useState(false);
  const { language } = useAppContext();

  const getServices = (id) => {
    request({
      url: `${apiPath.allServices}/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setServices(data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getCategory = () => {
    request({
      url: apiPath.allCategory,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setCategory(data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getSubCategory = (id) => {
    console.log("evalue", id);

    request({
      url: `${apiPath.allSubCategory}/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setSubCategory(data);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/webp",
    "image/gif",
  ];

  const handleImage = (data) => {
    //setImage(data);
    data.length > 0 ? setFile(data[0].url) : setFile([]);
    data.length > 0 ? setImage(data[0].url) : setImage([]);
  };

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    if (!paramsIds) return;
    form.setFieldsValue({
      category_id: paramsIds?.category_id,
      service_id: paramsIds?.service_id,
    });
    getCategory();
    getServices(paramsIds?.category_id);
    console.log("paramsIds", paramsIds);
  }, [paramsIds]);

  useEffect(() => {
    if (!data) return;
    console.log(data);
    form.setFieldsValue({
      ...data,
      category_id: data?.category_id?._id,
      service_id: data?.service_id
        ? data?.service_id?.map(({ _id }) => _id)
        : [],
      // sub_category_id: data?.sub_category_id?._id,
    });

    setFile([data.image]);
    setImage(data.image);
    getCategory();
    getServices(data?.category_id?._id);

    // getSubCategory(data?.category_id?._id);

    if (
      data.type === "radio" ||
      data.type === "checkbox" ||
      data.type === "dropdown" ||
      data.type === "color" ||
      data.type === "package"
    ) {
      if (data.type === "package") {
        setShowPackage(true);
      } else {
        setShowPackage(false);
      }
      setShowOptions(true);
    } else {
      setShowPackage(false);
      setShowOptions(false);
    }
  }, [data]);

  const onCreate = (values) => {
    // return console.log(values, "values");
    const payload = {
      ...values,
      country_id: country.country_id,
      category_id: values.category_id,
      // sub_category_id: values.sub_category_id,
    };

    setLoading(true);

    if (image?.length > 0) {
      payload.image = image;
    }

    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleOptions = (val) => {
    if (
      val === "radio" ||
      val === "checkbox" ||
      val === "dropdown" ||
      val === "color" ||
      val === "package"
    ) {
      if (val === "package") {
        setShowPackage(true);
      } else {
        setShowPackage(false);
      }
      setShowOptions(true);
      form.setFieldsValue({
        options: [{ name: "", ar_name: "", tags: [], duration: "" }],
      });
      console.log(val, "handleOptions");
    } else {
      setShowPackage(false);
      setShowOptions(false);
    }
  };

  // const attributeOption = [
  //   {
  //     label: lang("Package"),
  //     value: "package",
  //   },
  //   {
  //     label: lang("Dropdown"),
  //     value: "dropdown",
  //   },
  //   {
  //     label: lang("Radio"),
  //     value: "radio",
  //   },
  //   {
  //     label: lang("Button"),
  //     value: "button",
  //   },
  //   {
  //     label: lang("Checkbox"),
  //     value: "checkbox",
  //   },
  //   {
  //     label: lang("Input Number"),
  //     value: "inputNumber",
  //   },
  //   {
  //     label: lang("Calendar"),
  //     value: "calendar",
  //   },
  //   // {
  //   //   label: "File",
  //   //   value: "file",
  //   // },
  //   {
  //     label: lang("Color"),
  //     value: "color",
  //   },
  //   {
  //     label: lang("Boolean"),
  //     value: "boolean",
  //   },
  //   {
  //     label: lang("Textbox"),
  //     value: "textbox",
  //   },
  // ];

  return (
    <Modal
      open={show}
      maskClosable={false}
      width={750}
      okText={data ? lang(`Update`) : lang(`Add`)}
      cancelText={lang(`Cancel`)}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <Form
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
        initialValues={{
          is_active: true,
          options: [{ name: "", ar_name: "" }],
        }}
      >
        <h4 className="modal_title_cls">
          {data ? lang(`Edit Attribute`) : lang(`Add New Attribute`)}
        </h4>
        <Row gutter={[16, 0]}>
          <Col span={24} md={12}>
            <Form.Item
              label={<> {lang("Category Name")}</>}
              name="category_id"
              rules={[
                {
                  required: true,
                  message: lang("Please select the category!"),
                },
              ]}
            >
              <Select
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                placeholder={lang("Select Category")}
                onChange={(value, key) => {
                  getServices(value);
                  form.setFieldsValue({ service_id: [] });
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {category.map((item) => (
                  <Select.Option
                    key={item._id}
                    label={item.name}
                    value={item._id}
                  >
                    {language !== "en" && language !== null
                      ? item?.[`${language}_name`] ?? item?.name
                      : item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* <Col span={12} md={12}>
            <Form.Item
              label={lang(`Sub Category Name`)}
              name="sub_category_id"
              rules={[
                {
                  required: true,
                  message: lang("Please select the sub category!"),
                },
              ]}
            >
              <Select
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder={lang("Select Sub Category")}
                showSearch
                mode="single"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {subCategory.map((item) => (
                  <Select.Option
                    key={item._id}
                    label={item.name}
                    value={item._id}
                  >
                    {item.name}{" "}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          <Col span={24} md={12}>
            <Form.Item
              label={<> {lang("Service Name")}</>}
              name="service_id"
              rules={[
                {
                  required: true,
                  message: lang("Please select the Service!"),
                },
              ]}
            >
              <Select
                className="height-select"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder={lang("Select service")}
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                mode="multiple"
              >
                {services?.map((item) => (
                  <Select.Option
                    key={item?._id}
                    label={item?.name}
                    value={item?._id}
                  >
                    {language !== "en" && language !== null
                      ? item?.[`${language}_name`] ?? item?.name
                      : item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={<> {lang("Attribute Name")}</>}
              name="name"
              rules={[
                {
                  required: true,
                  message: lang("Name is required"),
                },
                {
                  max: 200,
                  message: lang(
                    "Name should not contain more then 200 characters!"
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input
                autoComplete="off"
                placeholder={lang(`Enter Attribute Name`)}
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={<> {lang("Attribute Name Arabic")}</>}
              name="ar_name"
              rules={[
                {
                  required: true,
                  message: lang("Arabic Name is required"),
                },
                {
                  max: 200,
                  message: lang(
                    "Name should not contain more then 200 characters!"
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`أدخل اسم الفئة`} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={<> {lang("Attribute Sort Order")}</>}
              name="sort"
              rules={[
                {
                  required: true,
                  message: lang("Attribute Sort Order is required"),
                },
              ]}
              // normalize={(value) => value.trimStart()}
            >
              <InputNumber
                maxLength={5}
                autoComplete="off"
                placeholder={`Enter Attribute Sort Order`}
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={<> {lang("Type")}</>}
              name="type"
              rules={[
                {
                  required: true,
                  message: lang("Please select the Attribute type!"),
                },
              ]}
            >
              <Select
                disabled={data ? true : false}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder={lang("Select Attribute")}
                showSearch
                mode="single"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val) => {
                  handleOptions(val);
                  // form.setFieldsValue({ options: null });
                }}
              >
                {attributeOption.map((item) => (
                  <Select.Option
                    key={item.value}
                    label={item.label}
                    value={item.value}
                  >
                    {item.label}{" "}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {showOptions && (
            <Col span={24} sm={24}>
              <Form.List
                name="options"
                className="mt-2"
                initialValue={[
                  { name: "", ar_name: "", tags: [], duration: "" },
                ]}
              >
                {(fields, { add, remove }, { form }) => (
                  <>
                    {fields.map((field, index) => (
                      <div key={field.key}>
                        <Space
                          key={field.key}
                          align="baseline"
                          className="gap-cls"
                        >
                          <Row gutter={[16, 0]}>
                            <Col span={24} sm={11}>
                              <Form.Item
                                className="qty-cls"
                                {...field}
                                name={[field.name, "name"]}
                                label={
                                  showPackage
                                    ? lang("Key Name")
                                    : lang("Attribute Option Name")
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: lang(
                                      "Please enter attribute option"
                                    ),
                                  },
                                ]}
                                normalize={(value) => value.trimStart()}
                              >
                                <Input
                                  autoComplete="off"
                                  placeholder={lang("Add Attribute Option")}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={20} sm={11}>
                              <Form.Item
                                className="qty-cls"
                                {...field}
                                name={[field.name, "ar_name"]}
                                label={
                                  showPackage
                                    ? lang("Key Arabic Name")
                                    : lang("Attribute Option Arabic Name")
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: lang(
                                      "Please enter attribute option"
                                    ),
                                  },
                                ]}
                                normalize={(value) => value.trimStart()}
                              >
                                <Input
                                  autoComplete="off"
                                  placeholder={`إضافة خيار الاختيار`}
                                />
                              </Form.Item>
                            </Col>
                            {showPackage && (
                              <>
                                <Col span={24} sm={12}>
                                  <Form.Item
                                    className="qty-cls"
                                    {...field}
                                    name={[field.name, "tags"]}
                                    label={lang("Attribute Tags")}
                                    rules={[
                                      {
                                        required: true,
                                        message: lang(
                                          "Please select attribute Tags"
                                        ),
                                      },
                                    ]}
                                  >
                                    <Select
                                      mode="tags"
                                      style={{
                                        width: "100%",
                                      }}
                                      placeholder="Tags Mode"
                                      // onChange={(value) => form.setFieldValue("tags", value)}
                                      options={[
                                        {
                                          value: "Dance Floor",
                                          label: "Dance Floor",
                                        },
                                        { value: "Music", label: "Music" },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>
                                {/* <Col span={16} sm={10} lg={9}>
                                  <Form.Item
                                    className="qty-cls"
                                    {...field}
                                    name={[field.name, "duration"]}
                                    label={lang("Attribute Duration")}
                                    rules={[
                                      {
                                        required: true,
                                        message: lang(
                                          "Please enter attribute duration"
                                        ),
                                      },
                                    ]}
                                    normalize={(value) => value.trimStart()}
                                  >
                                    <Input
                                      autoComplete="off"
                                      placeholder={`إضافة خيار الاختيار`}
                                    />
                                  </Form.Item>
                                </Col> */}
                              </>
                            )}

                            <Col span={4} sm={2} className="mt-4">
                              <div className="add-menu-items-delete">
                                {index === fields.length - 1 ? (
                                  <Form.Item className="addon-items">
                                    <Button
                                      onClick={() => add()}
                                      block
                                      className="primary_btn btnStyle"
                                    >
                                      <i class="fas fa-plus"></i>
                                      {/* {lang("Add")} */}
                                    </Button>
                                  </Form.Item>
                                ) : (
                                  <div className="minus-wrap">
                                    <DeleteOutlined
                                      className="delete-circal"
                                      onClick={() => remove(field.name)}
                                      style={{ borderRadius: "50%" }}
                                    />
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Space>
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
            </Col>
          )}
          <Col span={24} sm={24}>
            <div className="status_wrap">
              <Form.Item label={lang("Status")} name="is_active">
                <Radio.Group>
                  <Radio value={true}>{lang("Active")}</Radio>
                  <Radio value={false}>{lang("De-Active")}</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </Col>
          <Col span={12} sm={12}>
            <Form.Item
              label={lang("")}
              name="is_required"
              valuePropName="checked"
            >
              <Checkbox>{lang("Required")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
