import { Button, Col, Image, Card, Table, Tabs, Tooltip, Input, Row, Form, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { dateString } from "../../helper/functions";
import lang from "../../helper/langHelper";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader";
import { AppStateContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";


const ViewSupport = ({}) => {
  const { request } = useRequest();
  const [data, setData] = useState([]);
  const heading = lang("Support") + " " + lang("Management");
  const [loading, setLoading] = useState(false);
  const { setPageHeading } = useContext(AppStateContext);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const params = useParams();
 const [form] = Form.useForm()

  const getData = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.supportList}/view/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (!status) return;
        setData(data);
   
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, " : error : ");
      },
    });
  };

  const sendReply = (values) => {
    const payload = { ...values };
    
    request({
      url: `${apiPath.supportList}/reply/${params.id}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setShow(false);
          form.resetFields();
          setRefresh(()=> (prev) => !prev)
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err, Severty.ERROR);
        console.log(err);
      },
    });
  };

  useEffect(() => {
    if (!params.id) return;
    setLoading(true);
    getData(params.id);
  }, [params.id,refresh]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  return (
    <>
      

      <div className="table-responsive customPagination withOutSearilNo">
        {loading ? (
          <Loader />
        ) : (
          <Card className="quote-card " title={lang("View Details")}>
          <div className="view-main-list">
          { <div className="label-quote-card view-inner-cls">
             <h5> {lang("Details")} : </h5> <h6 className="view-quote-text view-quote-text-main987"><p>{data?.first_name}{" "}{data?.first_name}</p> <p>{data?.email}</p> </h6> 
            </div>}

            <div className="label-quote-card view-inner-cls">
             <h5> {lang("Message")} :</h5> <h6 className="view-quote-text view-quote-text-main987">{data?.message ? data?.message : ""}</h6> 
            </div>
            <div>
              {data?.reply_message ? 
            <div className="label-quote-card view-inner-cls">
             <h5> {lang("Reply")} :</h5> <h6 className="view-quote-text view-quote-text-main987">{data?.reply_message ? data?.reply_message : ""}</h6> 
            </div> : 
              <Button  className="primary_btn btnStyle">
              <a
                onClick={() =>setShow(true)}
              >         
                {lang("Send Reply")}
              </a>
            </Button> }
            </div>
           
          </div>
           
          </Card>
        )}
      </div>
      {show && (
        <Modal
          open={show}
          width={550}
          okText={lang("Submit")}
          cancelText={lang("Cancel")}
          onCancel={() => setShow(false)}
          okButtonProps={{
            form: "create",
            htmlType: "submit",
            loading: loading,
            className: "btn-primary-ft",
          }}
          className="tab_modal deleteWarningModal modal01"
          cancelButtonProps={{
            className: "btn-primary-cancel",
            htmlType: "Back",
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form
            id="create"
            form={form}
            onFinish={sendReply}
            layout="vertical"
            className="row-col"
          >
            <div className="add_user_title">
              <h4 className="modal_title_cls">{lang("Send Reply")}</h4>
            </div>
            <Row gutter={[16, 0]}>
              <Col xl={24} md={24} span={24}>
                <Form.Item
                  className="username"
                  name="reply_message"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter a Comment"),
                    },
                  ]}
                >
                  <Input.TextArea pl aceholder={lang("Enter Comment")} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default ViewSupport;
