import React, { useState, useEffect } from "react";
import { Button, Card } from "antd";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import lang from "../../helper/langHelper";


function ViewEmailTemplate() {
  const [selected, setSelected] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { request } = useRequest();
  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    request({
      url: `${apiPath.viewInvite}/${params.id}`,
      method: "GET",
      onSuccess: (data) => {
        setSelected(data?.data);
      },
    });
  }, [refresh]);

  return (

      <Card
        bordered={false}
        className="mb-24 email-template"
        title={
          <>
            <h6 className="font-semibold">{lang("Template Preview")}</h6>
          </>
        }
      >
        <div className="emailTempOuter">
          {/* <div className="emailHeader text-center">
            <img src={myntLogo} />
          </div> */}
        <div className="emailBody">
          {/* <div className="subTxt">{selected.subject}</div> */}
          {/* <div className="name">Hello Johan Doe,</div> */}
          {/* <div className="other">{selected.description}</div> */}
          <div dangerouslySetInnerHTML={{ __html: selected?.description }} />
          {/* <a href="" className="url text-primary">http://localhost:3000/email-templates/65081ddc9ce49f0b28a534cb/view-email</a>
            <div className="dec">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
            </div> */}
          </div>
        </div>

        <Button
          className="mt-3 new-button-of-view90"
          onClick={() => {
            window.history.back();
          }}
        >
          {lang("Back")}
        </Button>
      </Card>

  );
}

export default ViewEmailTemplate;
