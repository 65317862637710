import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
  TimePicker,
  Space,
  Image,
  Collapse,
  Steps,
  BackTop,
} from "antd";
import React, { useContext, useEffect, useState } from "react";

import {
  PhoneNumberField,
  SelectInput,
  TextInputBox,
} from "../../components/InputField";
import SingleImageUpload from "../../components/SingleImageUpload";
import MultipleImageUpload from "../../components/MultipleImageUpload";
import apiPath from "../../constants/apiPath";
import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import LocationMap from "../User/LocationMap";
import { Palestine } from "../../constants/var";
import { useAuthContext } from "../../context/AuthContext";
import moment from "moment";
import {
  BackwardOutlined,
  CaretRightOutlined,
  DeleteOutlined,
  DeleteRowOutlined,
  EditOutlined,
} from "@ant-design/icons";
import ImportForm from "../../components/ImportForm";
import { useNavigate, useParams } from "react-router";
import EditIcon from "../../assets/images/edit.svg";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import DeleteModal from "../../components/DeleteModal";
import { DeleteRestaurantReasons } from "../../constants/reasons";
import UploadImage from "../User/Customer/_UploadImage";
import BannerImageUpload from "../../components/BannerImageUpload";
import notfound from "../../assets/images/not_found.png";
import PhoneInput from "react-phone-input-2";



const weekdays = [
  { name: "sunday", label: "Sunday" },
  { name: "monday", label: "Monday" },
  { name: "tuesday", label: "Tuesday" },
  { name: "wednesday", label: "Wednesday" },
  { name: "thursday", label: "Thursday" },
  { name: "friday", label: "Friday" },
  { name: "saturday", label: "Saturday" },
];

const AddForm = () => {
  const api = {
    fetch: apiPath.getProvider,
    addEdit: apiPath.providerList,
    country: apiPath.common.countries,
    city: apiPath.common.city,
    rc: apiPath.common.restaurantCategories,
    category: apiPath.allCategory,
    SubCategory: apiPath.allSubCategory,
    subAdmin: apiPath.allSubAdmin,
    attribute: apiPath.allAttributeByCategory,
    eventType: apiPath.allEventType,
    updateProviderCategory: apiPath.updateProviderCategory,
  };
  const { Step } = Steps;
  const { Panel } = Collapse;
  const sectionName = lang("Provider");
  const routeName = "restaurant";
  const heading = sectionName + " " + lang("Management");
  const { setPageHeading } = useContext(AppStateContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const { request } = useRequest();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [areas, setAreas] = useState([]);
  const [document, setDocument] = useState();
  const [logo, setLogo] = useState();
  const [coverPhoto, setCoverPhoto] = useState("");
  const { country, language } = useAppContext();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [mobileNumber2, setMobileNumber2] = useState({});
  const [showInput, setShowInput] = useState([]);
  const [profile, setProfile] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [subAdmin, setSubAdmin] = useState([]);
  const [availableWorkingDays, setAvailableWorkingDays] = useState(weekdays);
  const [isUploadVisible, setIsUploadVisible] = useState(false);
  const [data, setData] = useState(null);
  const [workingDay, setWorkingDay] = useState([]);
  const [location, setLocation] = useState(null);
  const [editLocation, setEditLocation] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);
  const [services, setServices] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [event, setEvent] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [maxImgUpload, setMaxImgUpload] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();

  const format = "h:mm a";

  useEffect(() => {
    setPageHeading(heading);
  }, []);

  const getCategory = () => {
    request({
      url: api.category,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setCategory(data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getProfile = (id) => {
    console.log("evalue", id);

    request({
      url: `${api.addEdit}/profile/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setProfile(data);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getSubCategory = (id) => {
    console.log("evalue", id);

    request({
      url: `${api.SubCategory}/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setSubCategory(data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getServices = (id) => {
    request({
      url: `${apiPath.allServices}/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setServices(data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getSubAdmin = () => {
    request({
      url: api.subAdmin,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setSubAdmin(data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getAttribute = (category) => {
    request({
      url: `${api.attribute}/${category}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setAttributes(data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getEvent = () => {
    request({
      url: `${api.eventType}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          console.log(data);
          setEvent(data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getCountry = () => {
    request({
      url: apiPath.common.country,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setCountries(data.data);
        }
      },
    });
  };

  const getCity = (id) => {
    request({
      url: apiPath.common.city + id,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setCities(data?.data);
        }
      },
    });
  };

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const galleryFileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
    "video/mp4",
    "video/ogg",
    "video/webm",
    "video/mpeg"
  ];

  const getAreas = (id) => {
    request({
      url: `/city-area/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          setAreas(data);
        }
      },
    });
  };

  const handleImage = (value) => {
    setImage((prev) => {
      return value.map((item) => {
        return item.url;
      });
    });
  };

  const handleCoverPhoto = (data) => {
    data.length > 0  ? setCoverPhoto(data[0].url) : setCoverPhoto("");
  };

  const getProvider = (id) => {
    request({
      url: `${api.fetch}/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setData(data?.data);
        }
        console.log(data, "lll");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    getProvider(params.id);
  }, [params.id, refresh]);

  useEffect(() => {
    getCategory();
    getSubAdmin();
    getCountry();
  }, [refresh]);

  useEffect(() => {
    if (!data) return;

    console.log(data, "vendorEdit");
    form.setFieldsValue({
      ...data,
      mobile: data.country_code + data.mobile_number,
      mobile2: data?.vendor_id?.country_code_sec
        ? data?.vendor_id?.country_code_sec + data?.vendor_id?.mobile_number_sec
        : "",
      country_id: data?.country_id,
      city_id: data?.city_id,
      area: data?.area,
      category_id: data?.category_id,
      sub_category_id: data?.sub_category_id,
      associated_manager: data?.associated_manager,
      profile_id: data?.profile_id?._id,
      location_on_map: data.address,
      have_whatsapp: data?.have_whatsapp,
      working_days: data.working_days.map((day) => ({
        day: day.day,
        time: [moment(day.open_time), moment(day.close_time)],
      })),
      service_id: data.service_id,
    });
    setShowInput(data?.profile_id?.permission);
    setLogo(data.logo);
    setCoverPhoto(data?.cover_photo);
    setImage(data.image);
    setSelectedAttribute(data.values || []);
    setSelectedService(data.service_id || []);

    setLocation({
      map_address: data?.map_address,
      latitude: data?.latitude,
      longitude: data?.longitude,
    });

    setEditLocation({
      map_address: data?.map_address,
      latitude: data?.latitude,
      longitude: data?.longitude,
    });

    setMobileNumber({
      country_code: data?.country_code,
      mobile_number: data?.mobile_number,
    });

    const SelectCtgId = data?.selected_category_id?._id
      ? data?.selected_category_id?._id
      : data?.category_id?.[0];

    setMaxImgUpload(data?.number_of_images_gallary);
    getCategory();
    getSubAdmin();
    getProfile(SelectCtgId);
    getSubCategory(SelectCtgId);
    // getServices(SelectCtgId);
    // getAttribute(SelectCtgId);
    getEvent(SelectCtgId);
    getCity(data?.country_id);
    getCountry();
  }, [data]);

  const onCreate = (values) => {
    //  return console.log(values,formData, "hfjhdkghkhdgkd");
    const { location, working_days } = values;

    let payload = {
      ...values,
      logo: logo,
      cover_photo: coverPhoto,
      ...mobileNumber,
      ...mobileNumber2,
      ...location,
      document: document,
      category_id: values.category_id,
      sub_category_id: values.sub_category_id,
      image :  image.length ?  image : []
    };

  //  return console.log(payload, "payload");
    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          navigate(-1);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleMobileNumberChange = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data?.dialCode?.length),
    });
  };

  const onDelete = (id) => {
    request({
      url: api.addEdit + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        ShowToast(data.message, Severty.SUCCESS);
        navigate(-1);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleCategoryChange = (id) => {
    const filterCategory = category.filter((category) => category._id === id);
    setMaxImgUpload(filterCategory?.[0]?.number_of_images_gallary ?? 0);
  };

  return (
    <>
      {" "}
      <React.Fragment>
        {data ? (
          <Col span={24} lg={24} xs={24}>
            <div className="row g-3">
              <div className="col-12 d-flex align-items-center gap-3 justify-content-end">
                <Button
                  disabled={false}
                  onClick={() => navigate(-1)}
                  className="edit-cls btnStyle primary_btn deleteDangerbtn-main9999  py-2 px-3  d-flex align-items-center gap-1"
                >
                  {lang("Back")}
                </Button>
                <Button
                  disabled={!data}
                  onClick={() => setIsEdit(true)}
                  className="edit-cls btnStyle primary_btn deleteDangerbtn-main9999  py-2 px-3  d-flex align-items-center gap-1"
                >
                  <img src={EditIcon} />
                  {lang("Edit")}
                </Button>

                <Button
                  disabled={!data}
                  onClick={() => showDeleteModal(true)}
                  className="edit-cls btnStyle deleteDangerbtn py-2 px-3 d-flex align-items-center gap-1"
                >
                  <img src={deleteWhiteIcon} /> {lang("Delete")}{" "}
                </Button>
              </div>
            </div>
          </Col>
        ) : (
          <h4 className="modal_title_cls">{`${
            lang("Add") + " " + lang("Provider")
          }`}</h4>
        )}
        <Form
          id="create"
          form={form}
          onFinish={onCreate}
          layout="vertical"
          disabled={data ? !isEdit : false}
        >
          <Row gutter={[16, 16]}>
            <Col span={24} lg={24} xs={24} className="d-flex flex-column gap-3">
              <Card title={
                language !== "en" && language !== null
                ? data?.[`${language}_name`] ?? data?.name
                : data?.name      
               ?? "Provider Details"

              }>
                <Row gutter={[36]}>
                  <Col span={24} sm={12}>
                    <Form.Item
                      // label={lang("Category Name")}
                      label={
                        <div className="edit-main-email-09">
                          <h3>{lang("Category Name")}</h3>
                          <h4>
                            {
                              <span
                                onClick={() => {
                                  setVisible(true);
                                  setSelected(data);
                                }}
                              >
                                <EditOutlined /> {lang("Edit Category")}{" "}
                              </span>
                            }
                          </h4>
                        </div>
                      }
                      name="category_id"
                      rules={[
                        {
                          required: true,
                          message: lang("Please select the category!"),
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        disabled
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder={lang("Select Category")}
                        showSearch
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onChange={(e, key) => {
                          handleCategoryChange(e);
                          getProfile(e);
                          getSubCategory(e);
                          getServices(e);
                          getAttribute(e);
                          setShowInput([]);
                          form.setFieldsValue({ sub_category_id: [] });
                          form.setFieldsValue({ profile_id: [] });
                          form.setFieldsValue({ service_id: [] });
                        }}
                      >
                        {category.map((item) => (
                          <Select.Option
                            key={item._id}
                            label={item.name}
                            value={item._id}
                          >
                           { language !== "en" && language !== null
                ? item?.[`${language}_name`] ?? item?.name
                : item?.name }
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item
                      label={lang("Profile")}
                      name="profile_id"
                      rules={[
                        {
                          required: true,
                          message: lang("Please select the profile!"),
                        },
                      ]}
                    >
                      <Select
                        disabled
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder={lang("Select Profile")}
                        showSearch
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onChange={(value, option) => {
                          const selectedItem = profile.find(
                            (item) => item._id === value
                          );
                          if (selectedItem) {
                            setShowInput(selectedItem.permission);
                          }
                        }}
                      >
                        {profile.map((item) => (
                          <Select.Option
                            key={item._id}
                            label={item.name}
                            value={item._id}
                          >
                            { language !== "en" && language !== null
                ? item?.[`${language}_name`] ?? item?.name
                : item?.name }
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col span={24} lg={24} xs={24} className="d-flex flex-column gap-3">
              <Card className="">
                <h4 className="profile-headding">{lang("General")} </h4>

                <Row gutter={[24, 16]}>
                  {showInput?.map((item) => (
                    <React.Fragment key={item?._id}>
                      {item.is_selected && item.name === "name" && (
                        <Col span={24} md={8}>
                          <TextInputBox
                            name={"name"}
                            label={lang(item.label)}
                            placeholder={lang("Enter " + item.label)}
                            cover={{ md: 24 }}
                            colProps={{ sm: 24, span: 24 }}
                            rules={[
                              {
                                max: 250,
                                message: lang(
                                  "Name should contain more than 250 characters!"
                                ),
                              },
                              {
                                required: item.is_required,
                                message: lang("Please Enter " + item.label),
                              },
                            ]}
                          />
                        </Col>
                      )}
                      {item.is_selected && item.name === "ar_name" && (
                        <Col span={24} md={8}>
                          <TextInputBox
                            name={"ar_name"}
                            label={lang(item.label)}
                            placeholder={lang("Enter " + item.label)}
                            cover={{ md: 24 }}
                            colProps={{ sm: 24, span: 24 }}
                            rules={[
                              {
                                max: 250,
                                message: lang(
                                  "Name should contain more than 250 characters!"
                                ),
                              },
                              {
                                required: item.is_required,
                                message: lang("Please Enter " + item.label),
                              },
                            ]}
                          />
                        </Col>
                      )}
                      {item.is_selected && item.name === "description" && (
                        <Col span={24} md={8}>
                          <TextInputBox
                            name="description"
                            label={lang(item.label)}
                            placeholder={lang("Enter Business Description")}
                            cover={{ md: 24 }}
                            colProps={{ sm: 24, span: 24 }}
                            rules={[
                              {
                                max: 500,
                                message: lang(
                                  "Description should contain more then 500 characters!"
                                ),
                              },

                              {
                                required: item.is_required,
                                message: lang("Please Enter " + item.label),
                              },
                            ]}
                          />
                        </Col>
                      )}
                      {item.is_selected && item.name === "ar_description" && (
                        <Col span={24} md={8}>
                          <TextInputBox
                            name="ar_description"
                            label={lang("Business Description Arabic")}
                            placeholder={lang("Enter Business Description")}
                            cover={{ md: 24 }}
                            colProps={{ sm: 24, span: 24 }}
                            rules={[
                              {
                                max: 500,
                                message: lang(
                                  "Description should contain more then 500 characters!"
                                ),
                              },
                              {
                                required: item.is_required,
                                message: lang("Please Enter " + item.label),
                              },
                            ]}
                          />
                        </Col>
                      )}
                      {item.is_selected && item.name === "country_id" && (
                        <Col span={24} md={8}>
                          <Form.Item
                            label={lang("Country")}
                            name="country_id"
                            rules={[
                              {
                                required: item.is_required,
                                message: lang("Please select the country!"),
                              },
                            ]}
                          >
                            <Select
                              filterOption={(input, option) =>
                                option?.label
                                  ?.toLowerCase()
                                  .indexOf(input?.toLowerCase()) >= 0
                              }
                              autoComplete="off"
                              placeholder={lang("Select Country")}
                              showSearch
                              onChange={(value) => getCity(value)}
                            >
                              {countries.map((item) => (
                                <Select.Option
                                  key={item._id}
                                  label={item.name}
                                  value={item._id}
                                >
                                  {item.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      )}
                      {item.is_selected && item.name === "city_id" && (
                        <Col span={24} md={8}>
                          <SelectInput
                            name="city_id"
                            label={lang("City")}
                            placeholder={lang("Select City")}
                            showSearch
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            options={cities}
                            cover={{ md: 24 }}
                            colProps={{ sm: 24, span: 24 }}
                            rules={[
                              {
                                required: item.is_required,
                                message: lang("Please Enter " + item.label),
                              },
                            ]}
                            handleChange={(value) => {
                              getAreas(value);
                              form.setFieldsValue({ area: null });
                            }}
                          />
                        </Col>
                      )}

                      {item.is_selected && item.name === "sub_category_id" && (
                        <Col span={24} md={8}>
                          <Form.Item
                            label={lang(`Sub Category Name`)}
                            name="sub_category_id"
                            rules={[
                              {
                                required:
                                  subCategory?.length && item.is_required
                                    ? true
                                    : false,
                                message: lang("Please Enter " + item.label),
                              },
                            ]}
                          >
                            <Select
                              filterOption={(input, option) =>
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              placeholder={lang("Select Sub Category")}
                              showSearch
                              mode="multiple"
                            >
                              {subCategory?.map((item) => (
                                <Select.Option
                                  key={item._id}
                                  label={item.name}
                                  value={item._id}
                                >
                                   { language !== "en" && language !== null
                ? item?.[`${language}_name`] ?? item?.name
                : item?.name }
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      )}
                      {item.is_selected &&
                        item.name === "associated_manager" && (
                          <Col span={24} md={8}>
                            <Form.Item
                              label={lang("Associated manager")}
                              name="associated_manager"
                              rules={[
                                {
                                  required: item.is_required,
                                  message: lang("Please Enter " + item.label),
                                },
                              ]}
                            >
                              <Select
                                filterOption={(input, option) =>
                                  option.label
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={lang("Select Associated manager")}
                                showSearch
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                              >
                                {subAdmin.map((item) => (
                                  <Select.Option
                                    key={item._id}
                                    label={item.name}
                                    value={item._id}
                                  >
                                   { language !== "en" && language !== null
                ? item?.[`${language}_name`] ?? item?.name
                : item?.name }
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        )}
                    </React.Fragment>
                  ))}
                </Row>
              </Card>
            </Col>

            <Col span={24} lg={24} xs={24} className="d-flex flex-column gap-3">
              <Card className="">
                <h4 className="profile-headding">{lang("Contact")}</h4>
                <Row gutter={[12]}>
                  {showInput?.map((item) => (
                    <React.Fragment key={item._id}>
                      {item.is_selected &&
                        item.name === "contact_person_name" && (
                          <Col span={24} md={8}>
                            <TextInputBox
                              name="contact_person_name"
                              label={lang("Contact Person Name")}
                              placeholder={lang("Enter Contact Person Name")}
                              cover={{ md: 24 }}
                              colProps={{ sm: 24, span: 24 }}
                              rules={[
                                {
                                  max: 100,
                                  message: lang(
                                    "Name should contain more then 100 characters!"
                                  ),
                                },
                                {
                                  required: item.is_required,
                                  message: lang("Please Enter " + item.label),
                                },
                              ]}
                            />
                          </Col>
                        )}
                      {item.is_selected && item.name === "email" && (
                        <Col span={24} md={8}>
                          <Form.Item
                            label={lang("Email ID")}
                            name="email"
                            cover={{ md: 24 }}
                            colProps={{ sm: 24, span: 24 }}
                            rules={[
                              {
                                type: "email",
                                message: lang(
                                  "Please enter a valid email address!"
                                ),
                              },
                              {
                                max: 50,
                                message: lang(
                                  "Email address not more then 255 characters!"
                                ),
                              },
                              {
                                required: item.is_required,
                                message: lang("Please Enter " + item.label),
                              },
                            ]}
                          >
                            <Input placeholder={lang("Enter Email ID")} />
                          </Form.Item>
                        </Col>
                      )}
                      {item.is_selected && item.name === "mobile" && (
                        // <Col span={24} md={8}>
                        //   <PhoneNumberField
                        //     rules={item.is_required}
                        //     label={lang("Contact Person Phone Number")}
                        //     name="mobile"
                        //     placeholder={lang("Enter Phone Number")}
                        //     cover={{ md: 24 }}
                        //     colProps={{ sm: 24, span: 24 }}
                        //     className=""
                        //     onChange={handleMobileNumberChange}
                        //   />
                        // </Col>
                        <Col span={24} md={8}>
                          <Form.Item
                            label={lang("Contact Person Phone Number")}
                            name="mobile"
                            rules={[
                              {
                                required: item.is_required,
                                validator: (rule, value) => {
                                  if (!value) {
                                    return Promise.reject(
                                      lang("Please enter phone number")
                                    );
                                  }
                                  if (
                                    !/^\d{8,12}$/.test(
                                      mobileNumber?.mobile_number
                                    )
                                  ) {
                                    return Promise.reject(
                                      lang(
                                        "Phone number must be between 8 and 12 digits"
                                      )
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <PhoneInput
                              disabled={data ? !isEdit : false}
                              inputProps={{
                                name: "mobile",
                                required: item.is_required,
                                autoFocus: false,
                                autoFormat: false,
                                autoComplete: "off",
                              }}
                              isValid={(value, country) => {
                                if (value.match(/1234/)) {
                                  return (
                                    "Invalid value: " +
                                    value +
                                    ", " +
                                    country.name
                                  );
                                } else {
                                  return true;
                                }
                              }}
                              country={"jo"}
                              preferredCountries={["jo"]}
                              onChange={handleMobileNumberChange}
                            />
                          </Form.Item>
                        </Col>
                      )}
                      {item.is_selected && item.name === "password" && (
                        <Col span={24} md={8}>
                          <Form.Item
                            label={lang("Create Password")}
                            name="password"
                            cover={{ md: 24 }}
                            colProps={{ sm: 24, span: 24 }}
                            rules={
                              [
                                // {
                                //   pattern: new RegExp(
                                //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
                                //   ),
                                //   message: lang(
                                //     "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character"
                                //   ),
                                // },
                                // {
                                //   required: item.is_required,
                                //   message: lang("Please Enter " + item.label),
                                // },
                              ]
                            }
                          >
                            <Input.Password
                              onCut={(e) => e.preventDefault()}
                              onCopy={(e) => e.preventDefault()}
                              onPaste={(e) => e.preventDefault()}
                              autoComplete="off"
                              placeholder={lang("Create Password")}
                            />
                          </Form.Item>
                        </Col>
                      )}
                      {item.is_selected && item.name === "password" && (
                        <Col span={24} md={8}>
                          <Form.Item
                            label={lang("Confirm Password")}
                            name="confirm_password"
                            dependencies={["password"]}
                            cover={{ md: 24 }}
                            colProps={{ sm: 24, span: 24 }}
                            hasFeedback
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (getFieldValue("password") || value) {
                                    // Make confirm password required if password field is filled
                                    if (!value) {
                                      return Promise.reject(
                                        new Error(
                                          lang("Please confirm your password!")
                                        )
                                      );
                                    } else if (
                                      getFieldValue("password") !== value
                                    ) {
                                      return Promise.reject(
                                        new Error(
                                          lang("Passwords do not match!")
                                        )
                                      );
                                    }
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input.Password
                              placeholder={lang("Enter Confirm Password")}
                            />
                          </Form.Item>
                        </Col>
                      )}

                      {/* {item.is_selected && item.name === "mobile" && (
                        <Col span={24} md={8}>
                          <Form.Item
                            name="have_whatsapp"
                            valuePropName="checked"
                            cover={{ md: 24 }}
                            colProps={{ sm: 24, span: 24 }}
                          >
                            <Checkbox className="" onChange={onChange}>
                              {lang(
                                "This number have WhatsApp to receive messages?"
                              )}{" "}
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      )} */}
                    </React.Fragment>
                  ))}
                </Row>
              </Card>

              <Card>
                <h4 className="profile-headding">{lang("Media")} </h4>
                <div className="row g-3">
                  {showInput?.map((item) => (
                    <React.Fragment key={item._id}>
                      {item.is_selected && item.name === "website_url" && (
                        <Col span={24} md={12}>
                          <TextInputBox
                            name={"twitter_link"}
                            label={lang("Website Link")}
                            placeholder={lang("Enter Website Link")}
                            cover={{ md: 24 }}
                            colProps={{ sm: 24, span: 24 }}
                            rules={[
                              {
                                required: item.is_required,
                                message: lang("Please enter Website link "),
                              },
                            ]}
                          />
                          <TextInputBox
                            name={"facebook_link"}
                            label={lang("FaceBook Link")}
                            placeholder={lang("Enter FaceBook Link")}
                            cover={{ md: 24 }}
                            colProps={{ sm: 24, span: 24 }}
                            rules={[
                              {
                                required: item.is_required,
                                message: lang("Please Enter facebook link"),
                              },
                            ]}
                          />
                          <TextInputBox
                            name={"instagram_link"}
                            label={lang("Instagram Link")}
                            placeholder={lang("Enter Instagram Link")}
                            cover={{ md: 24 }}
                            colProps={{ sm: 24, span: 24 }}
                            rules={[
                              {
                                required: item.is_required,
                                message: lang("Please Enter Instagram link"),
                              },
                            ]}
                          />
                        </Col>
                      )}

                      {item.is_selected && item.name === "logo" && (
                        <div className="col-md-4">
                          <Form.Item
                            className="upload_wrap"
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (item.is_required && !logo) {
                                    return Promise.reject(
                                      lang(`Please Select ${item.label}`)
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                            name="logo"
                            label={lang("Upload Profile Image")}
                          >
                            <UploadImage
                              disabled={data ? !isEdit : false}
                              value={logo}
                              setImage={setLogo}
                              isDimension={true}
                              size={5}
                            />
                          </Form.Item>
                          <p
                            className="img-size-details"
                            style={{ fontSize: 11, marginTop: 4 }}
                          ></p>
                          <p className="img-size-details">
                        **
                          {lang(
                          `Logo should be (300-800)X(300-800) for best view in profile image. You can select only ( .png, .jpg) format files upto 5 MB file size`,
                        )}
                        ..!!!
                      </p> 
                        </div>
                      )}
                      {item.is_selected && item.name === "cover_photo" && (
                        <div className="col-md-4">
                          <Form.Item
                            className="upload_wrap"
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (item.is_required && !logo) {
                                    return Promise.reject(
                                      lang(`Please Select ${item.label}`)
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                            name="cover_photo"
                            label={lang("Upload Thumbnail")}
                          >
                            {/* <UploadImage
                              disabled={data ? !isEdit : false}
                              value={coverPhoto}
                              setImage={setCoverPhoto}
                            /> */}
                            <SingleImageUpload
                              value={coverPhoto}
                              fileType={FileType}
                              imageType={"coverPhoto"}
                              btnName={lang("Thumbnail")}
                              onChange={(data) => handleCoverPhoto(data)}
                              isDimension={true}
                              size={5}
                              width={1400}
                              height={800}
                            />
                          </Form.Item>

                          <div className="mt-2 add-img-product">
                            {" "}
                            {coverPhoto ? <Image
                              width={120}
                              src={coverPhoto ?? notfound}
                            ></Image> : ""}{" "}
                          </div>
                          <p className="img-size-details">
                            **
                            {lang(
                              "Images should be 1400x800 for best view in cover image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 5 MB file size"
                            )}
                            ..!!!
                          </p>
                        </div>
                      )}
                      {item.is_selected && item.name === "location" && (
                        <div className="col-md-12">
                          <Form.Item
                            label={lang(
                              "Location (Drag Marker for Selecting Location)"
                            )}
                            name="location"
                            rules={[
                              {
                                required: item.is_required,
                                message: lang("Please Enter " + item.label),
                              },
                            ]}
                          >
                            <LocationMap
                              disabled={data ? !isEdit : false}
                              className="mt-3"
                              editLocation={editLocation}
                            />
                          </Form.Item>
                        </div>
                      )}

                      {item.is_selected && item.name === "image" && (
                        <div className="col-md-6">
                          <Card className="" style={{ height: "100%" }}>
                            <div className="row g-3">
                              <Col span={24}>
                                <Form.Item
                                  className="upload_wrap img-uploaded"
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (
                                          item.is_required &&
                                          (!image || image.length === 0)
                                        ) {
                                          return Promise.reject(
                                            lang(`Please Select ${item.label}`)
                                          );
                                        }
                                        return Promise.resolve();
                                      },
                                    }),
                                  ]}
                                  label={lang("Upload Gallery Image")}
                                  name="images"
                                  getValueFromEvent={(event) => {
                                    return event
                                      .map((item) => item.thumbUrl)
                                      .join(",");
                                  }}
                                >
                                  <MultipleImageUpload
                                    disabled={data ? !isEdit : false}
                                    maxCount={maxImgUpload}
                                    value={image}
                                    data={image}
                                    fileType={galleryFileType}
                                    imageType={"providerGalllery"}
                                    btnName={"Image"}
                                    onChange={(data) => handleImage(data)}
                                  />
                                   <p className="img-size-details">
                                      **
                                      {lang(
                                        `${lang("Images should be all size accepted for best view in gallery. You can select only")} ${maxImgUpload} ${lang("images or videos (.gif, .png, .jpeg, .jpg, .mp4, .ogg, .webm) format files upto 5 MB file size")}`,
                                      )}
                                      ..!!!
                                    </p>
                                </Form.Item>
                              </Col>
                            </div>
                          </Card>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </Card>
              <Card>
                <h4 className="profile-headding">{lang("Select Event")} </h4>
                <div className="row g-3">
                  {showInput?.map((item) => (
                    <React.Fragment key={item._id}>
                      {item.is_selected && item.name === "eventtype_id" && (
                        <div className="col-md-12">
                          <div className="row g-3">
                            <Col span={24} md={24}>
                              <Form.Item
                                // label="Event"
                                name="eventtype_id"
                                rules={[
                                  {
                                    required: item.is_required,
                                    message: lang("Please Enter "),
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select Event"
                                  // onChange={(e) => {
                                  //   handleSelectWorkDays(e);
                                  // }}
                                  filterOption={(input, option) =>
                                    option?.label
                                      ?.toLowerCase()
                                      ?.indexOf(input?.toLowerCase()) >= 0
                                  }
                                  getPopupContainer={(triggerNode) =>
                                    triggerNode.parentNode
                                  }
                                  mode="multiple"
                                  showSearch
                                >
                                  {event.map((item) => (
                                    <Select.Option
                                      value={item._id}
                                      key={item._id}
                                      label={item.name}
                                    >
                                        { language !== "en" && language !== null
                ? item?.[`${language}_name`] ?? item?.name
                : item?.name }
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </Card>

              <Card className="">
                <h4 className="profile-heading">{lang("Working Day's")}</h4>
                <div className="row g-3">
                  <div className="  col-lg-12  px-0">
                    <Row gutter={24}>
                      {showInput?.map((item) => (
                        <React.Fragment key={item._id}>
                          {item.is_selected && item.name === "working_days" && (
                            <Col span={24} md={24}>
                              <Form.List
                                name="working_days"
                                className="mt-2 "
                                initialValue={[{}]}
                              >
                                {(fields1, { add, remove }, {}) => (
                                  <>
                                    {fields1.map((field_fr_1, index) => {
                                      const selectedDays = form
                                        .getFieldValue("working_days")
                                        ?.map((item, idx) =>
                                          idx !== index ? item?.day : null
                                        )
                                        .filter(Boolean);
                                      return (
                                        <div key={field_fr_1.key}>
                                          <Space
                                            key={field_fr_1.key}
                                            align="baseline"
                                            className="gap-cls"
                                          >
                                            <div className="row gap-3 m-3"></div>
                                            <div className="row gap-3 m-3">
                                              <Col span={24} md={8}>
                                                <Form.Item
                                                  label="Select Day"
                                                  name={[
                                                    field_fr_1.name,
                                                    "day",
                                                  ]}
                                                  rules={[
                                                    {
                                                      required:
                                                        item.is_required,
                                                      message: lang(
                                                        "Please Enter " +
                                                          item.label
                                                      ),
                                                    },
                                                  ]}
                                                >
                                                  <Select
                                                    placeholder="Select Working Day"
                                                    onChange={(value) => {
                                                      // Manually set the selected day in the form
                                                      form.setFieldsValue({
                                                        working_days: form
                                                          .getFieldValue(
                                                            "working_days"
                                                          )
                                                          .map((dayItem, idx) =>
                                                            idx === index
                                                              ? {
                                                                  ...dayItem,
                                                                  day: value,
                                                                }
                                                              : dayItem
                                                          ),
                                                      });
                                                    }}
                                                    // onChange={(e) => {
                                                    //   handleSelectWorkDays(e);
                                                    // }}
                                                    getPopupContainer={(
                                                      triggerNode
                                                    ) => triggerNode.parentNode}
                                                  >
                                                    {availableWorkingDays.map(
                                                      (day) => (
                                                        <Select.Option
                                                          value={day.name}
                                                          key={day.name}
                                                          disabled={selectedDays?.includes(
                                                            day?.name
                                                          )}
                                                        >
                                                          {day.label}
                                                        </Select.Option>
                                                      )
                                                    )}
                                                  </Select>
                                                </Form.Item>
                                              </Col>
                                              <Col span={24} md={8}>
                                                <Form.Item
                                                  className="qty-cls "
                                                  style={{
                                                    minWidth: "180px",
                                                  }}
                                                  name={[
                                                    field_fr_1.name,
                                                    "time",
                                                  ]}
                                                  label="Enter Time Range"
                                                  rules={[
                                                    {
                                                      required:
                                                        item.is_required,
                                                      message: lang(
                                                        "Please Enter Time Range "
                                                      ),
                                                    },
                                                  ]}
                                                >
                                                  <TimePicker.RangePicker
                                                    defaultValue={moment(
                                                      "12:08",
                                                      format
                                                    )}
                                                    format={format}
                                                  />
                                                </Form.Item>
                                              </Col>

                                              {/* <Col span={24} md={2}>
                                              <div className="addDelete_option">
                                                {index_fr_1 > 0 ? (
                                                  <div className="minus-wrap">
                                                    <div
                                                      className="delete-circal"
                                                      onClick={() =>
                                                        remove(field_fr_1.name)
                                                      }
                                                      style={{
                                                        borderRadius: "50%",
                                                        color: "#000",
                                                      }}
                                                    >
                                                      <DeleteOutlined />
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </div>
                                            </Col> */}
                                              <Col
                                                span={8}
                                                sm={5}
                                                lg={6}
                                                className="mt-4"
                                              >
                                                <div className="add-menu-items-delete">
                                                  {index ===
                                                  field_fr_1.length - 1 ? (
                                                    <Form.Item className="addon-items">
                                                      <Button
                                                        onClick={() => add()}
                                                        block
                                                        className="primary_btn btnStyle"
                                                      >
                                                        <i class="fas fa-plus me-1"></i>
                                                        {lang("Add")}
                                                      </Button>
                                                    </Form.Item>
                                                  ) : (
                                                    <div className="minus-wrap">
                                                      <DeleteOutlined
                                                        className="delete-circal"
                                                        onClick={() =>
                                                          remove(
                                                            field_fr_1.name
                                                          )
                                                        }
                                                        style={{
                                                          borderRadius: "50%",
                                                        }}
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                              </Col>
                                            </div>
                                          </Space>
                                        </div>
                                      );
                                    })}

                                    <Col md={6} span={24}>
                                      <Form.Item style={{ marginTop: "10px" }}>
                                        <Button
                                          onClick={() => add()}
                                          block
                                          className="primary_btn btnStyle add-item-btn"
                                        >
                                          <i className="fas fa-plus" />{" "}
                                          {"            "}
                                          <span style={{ paddingLeft: "10px" }}>
                                            {lang("Add Another Day")}
                                          </span>
                                        </Button>
                                      </Form.Item>
                                    </Col>
                                  </>
                                )}
                              </Form.List>
                            </Col>
                          )}
                        </React.Fragment>
                      ))}
                    </Row>
                  </div>
                </div>
              </Card>
            </Col>
            <div className="view-provider">
              <Button
                type="primary"
                onClick={(e) => navigate(-1)}
                disabled={false}
              >
                {lang("Cancel")}
              </Button>
              <Button form="create" type="primary" htmlType="submit">
                {lang("Submit")}
              </Button>
            </div>
          </Row>
        </Form>
      </React.Fragment>
      {isUploadVisible && (
        <ImportForm
          path={api.import}
          sectionName={"import Provider"}
          show={isUploadVisible}
          hide={() => setIsUploadVisible(false)}
          existingData={[]}
        />
      )}
      {deleteModal && (
        <DeleteModal
          title={lang("Delete Service Provider")}
          subtitle={lang(
            `Are you sure you want to Delete this Service Provider?`
          )}
          show={deleteModal}
          hide={() => {
            showDeleteModal(false);
          }}
          reasons={DeleteRestaurantReasons}
          onOk={() => onDelete(data?._id)}
        />
      )}
      {visible && (
        <UpdateCategoryForm
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          categories={category}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
    </>
  );
};

const UpdateCategoryForm = ({ categories, api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const { language } = useAppContext();
  const { setRefreshUserProfile } = useAuthContext();
  const [disabledCtg, setDisabledCtg] = useState([]);

  useEffect(() => {
    if (!data) return;

    form.setFieldsValue({
      ...data,
    });

    setDisabledCtg(data?.category_id);
  }, [data]);

  const onCreate = (values) => {
    const { category_id } = values;
    const payload = {};
    setLoading(true);
    payload.category_id = category_id;
    request({
      url: `${api.updateProviderCategory}/${data?._id}`,
      method: "PUT",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
          setRefreshUserProfile((prev) => !prev);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      maskClosable={false}
      open={show}
      width={750}
      okText={data ? lang("Update") : lang("Add")}
      onCancel={hide}
      okButtonProps={{
        form: "updatecategory",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
      cancelText={lang("Cancel")}
    >
      <Form
        id="updatecategory"
        form={form}
        onFinish={onCreate}
        layout="vertical"
      >
        <div className="add_user_title">
          <h4 className="modal_title_cls">{`${lang("Edit Category")}`}</h4>
        </div>
        <Row gutter={[24, 0]}>
          <Col span={24} md={24}>
            <Form.Item
              label={lang("Category Name")}
              name="category_id"
              rules={[
                {
                  required: true,
                  message: lang("Please select the category!"),
                },
              ]}
            >
              <Select
                mode="multiple"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder={lang("Select Category")}
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {categories.map((item) => (
                  <Select.Option
                    key={item._id}
                    label={item.name}
                    value={item._id}
                    disabled={disabledCtg?.includes(item?._id)}
                  >
                    {language !== "en" && language !== null
                      ? item?.[`${language}_name`] ?? item?.name
                      : item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
