import { Button, Col, Image, Card, Table, Tabs, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { dateString } from "../../helper/functions";
import lang from "../../helper/langHelper";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader";
import { AppStateContext } from "../../context/AppContext";


const ViewReport = ({}) => {
  const { request } = useRequest();
  const [data, setData] = useState([]);
  const heading = lang("Report") + " " + lang("Management");
  const [loading, setLoading] = useState(false);
  const { setPageHeading } = useContext(AppStateContext);
  const navigate = useNavigate();

  const params = useParams();
 

  const getData = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.reportList}/view/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (!status) return;
        setData(data);
   
      },
      onError: (err) => {
        setLoading(false);
        console.log(err, " : error : ");
      },
    });
  };

  useEffect(() => {
    if (!params.id) return;
    setLoading(true);
    getData(params.id);
  }, [params.id]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  return (
    <>
      

      <div className="table-responsive customPagination withOutSearilNo">
        {loading ? (
          <Loader />
        ) : (
          <Card className="quote-card " title={lang("View Details")}>
          <div className="view-main-list">
          {data?.vendor_id  ? <div className="label-quote-card view-inner-cls">
             <h5> {lang("Provider Details")} : </h5> <h6 className="view-quote-text view-quote-text-main987"><p>{data?.vendor_id?.name}</p> <p>{data?.vendor_id?.email}</p> </h6> 
            </div> : "" }
        
            {data?.user_id ? <div className="label-quote-card view-inner-cls">
            <h5>   {lang("Reporter Details")} : </h5><h6 className="view-quote-text view-quote-text-main987"><p>{data?.user_id?.name}</p><p> {data?.user_id?.email}</p></h6>
            </div> : "" }
         
           
            
         
            <div className="label-quote-card view-inner-cls">
             <h5> {lang("Report Message")} :</h5> <h6 className="view-quote-text view-quote-text-main987">{data?.comment ? data?.comment : ""}</h6> 
            </div>

          </div>
           
          </Card>
        )}
      </div>
     
    </>
  );
};

export default ViewReport;
