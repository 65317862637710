import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Image,
  Avatar,
  Tooltip,
} from "antd";
import {
  HeartOutlined,
  SendOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import React, { useContext, useEffect, useState, useRef } from "react";
import SingleImageUpload from "../../components/SingleImageUpload";
import apiPath from "../../constants/apiPath";
import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import EditIcon from "../../assets/images/edit.svg";
import PlusIcon from "../../assets/images/plus-icon.svg";
import ScrollContainer from "react-indiana-drag-scroll";
import notfound from "../../assets/images/not_found.png";
import Loader from "../../components/Loader";
import Stories from "react-insta-stories";
import { AuthContext, useAuthContext } from "../../context/AuthContext";
import { formatStringDate, formatTimeAgo } from "../../helper/functions";
import Prouser from "../../assets/images/user.png";
import { useParams } from "react-router";

const Highlight = () => {
  const api = {
    fetch: apiPath.getProvider,
    addEdit: apiPath.updateProvider,
    category: apiPath.allCategory,
    SubCategory: apiPath.allSubCategory,
    subAdmin: apiPath.allSubAdmin,
    attribute: apiPath.allAttributeByCategory,
    eventType: apiPath.allEventType,
  };
  const sectionName = lang("Highlight");
  const heading = sectionName + " " + lang("Management");
  const { setPageHeading } = useContext(AppStateContext);
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [showStory, setShowStory] = useState(false);
  const [storyData, setStoryData] = useState("");
  const [maxStoryUpload, setMaxStoryUpload] = useState(0);
  const [proStoryCount, setProStoryCount] = useState(0);
  const supportedVideoExtensions = [".mp4", ".ogg", ".webm", ".mpeg"];
  const params = useParams()
 

  const [showInput, setShowInput] = useState([]);

  const [data, setData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    setPageHeading(heading);
  }, []);




 

  const getProvider = (id) => {
    setLoading(true);

    request({
      url: apiPath.getProviderStory + "/" + id,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        if (status) {
          setData(data.data);
          setShowInput(data.data?.profile_id?.permission);
        }
        console.log(data, "lll");
      },
      onError: (err) => {
        setLoading(false);

        console.log(err);
      },
    });
  };

  useEffect(() => {
    if(!params.id) return ;
    setLoading(true);
    getProvider(params.id);
  }, [refresh,params]);

  useEffect(() => {
    if (!data) return;
    console.log(data, "vendorEdit");
    form.setFieldsValue({
      ...data,
    });
    setMaxStoryUpload(data?.number_of_highlights);
    setImage(data?.image);
    let storyCount = data?.story?.length || 0;
    setProStoryCount(storyCount);
  }, [data]);



  const isVideo = (url) => {
    return supportedVideoExtensions.some((ext) =>
      url?.toLowerCase().endsWith(ext)
    );
  };

  return (
    <>
      {" "}
      <React.Fragment>
      

        <Form
          id="create"
          form={form}
          onFinish={"onCreate"}
          layout="vertical"
          disabled={data ? !isEdit : false}
        >
          <Row gutter={[16, 16]}>
            <Col span={24} lg={24} xs={24} className="d-flex flex-column gap-3">
              <Card className="main-newheader-ofall" title={lang("Provider Highlights")}>
                {/* <div className="headding">
                  <h4>{lang("Story")}</h4>
                </div> */}
                <div className="section-story">
                  {proStoryCount < maxStoryUpload ? (
                    <div className="btn-story-section">
                      <div
                        className="add-story-btn"
                        onClick={() => {
                          setShow(true);
                          setSelected(data);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={PlusIcon} alt="Add Story" />
                      </div>
                      {lang("Add New Story")}
                    </div>
                  ) : (
                    <div className="section-story">
                      <b>{`you can added only ${maxStoryUpload} Stories`}</b>
                    </div>
                  )}
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="scroll-bar">
                      {data?.story?.length > 0 ? (
                        <ScrollContainer className="scroll-container">
                          <div className="story-container">
                            {data?.story?.map((item) => (
                              <div className="stories-grid">
                                {
                                  <div
                                    key={item?._id}
                                    className="story-item"
                                    onClick={() => {
                                      setShowStory(true);
                                      setStoryData(item);
                                    }}
                                  >
                                    <div className="avatar-container">
                                      {isVideo(item?.image) ? (
                                        // <video controls src={item?.image} />
                                        <Avatar
                                          src={
                                            data?.logo
                                              ? data?.logo
                                              : data?.selected_category_id
                                                  ?.image
                                              ? data?.selected_category_id
                                                  ?.image
                                              : item?.image
                                          }
                                        />
                                      ) : (
                                        <Avatar src={item?.image} />
                                      )}
                                    </div>
                                    <Tooltip
                                      title={
                                        item?.liked_by?.length
                                          ? `Liked by - ${
                                              item?.liked_by?.length
                                                ? item?.liked_by
                                                    ?.map((itm) => itm?.name)
                                                    .join(", ")
                                                : ""
                                            }`
                                          : "No Likes"
                                      }
                                    >
                                      <span className="man-34span">
                                        {formatStringDate(item?.created_at)}
                                      </span>
                                    </Tooltip>
                                  </div>
                                }
                              </div>
                            ))}
                          </div>
                        </ScrollContainer>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </Card>
            </Col>
          
            {isEdit ? (
              <div className="view-provider">
                <Button form="create" type="primary" htmlType="submit">
                  {lang("Save")}
                </Button>
              </div>
            ) : (
              ""
            )}
          </Row>
        </Form>
      </React.Fragment>
      {show && (
        <AddStory
          show={show}
          hide={() => {
            setShow(false);
            setSelected("");
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {showStory && (
        <ReadStory
          show={showStory}
          hide={() => {
            setShowStory(false);
            setStoryData("");
          }}
          data={storyData}
          provider={data}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
    </>
  );
};

const AddStory = ({ show, hide, refresh, data }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const supportedVideoExtensions = [".mp4", ".ogg", ".webm", ".mpeg"];
  const params = useParams()
  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
    "video/mp4",
    "video/ogg",
    "video/webm",
    "video/mpeg",
  ];

  let video = supportedVideoExtensions.some((ext) =>
    file?.url?.toLowerCase().endsWith(ext)
  );

  const handleImage = (data) => {
    data.length > 0 ? setImage(data[0].url) : setImage([]);
  };

  const onCreate = (values) => {
    console.log(values, "values");
    setLoading(true);
    const payload = { ...values };
    if (image?.length > 0) {
      payload.image = image;
    }
    payload.provider_id = params.id

    request({
      url: `${apiPath.addStory}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      width={800}
      open={show}
      okText={lang("Ok")}
      cancelText={lang("Cancel")}
      onCancel={hide}
      centered
      okButtonProps={{
        form: "add-story",
        htmlType: "submit",
        loading: loading,
      }}
      className="tab_modal deleteWarningModal deleteWarningModal-ffg454"
    >
      <Form
        className="p-2"
        id="add-story"
        form={form}
        onFinish={onCreate}
        layout="vertical"
      >
        <h4 className="modal_title_cls">{lang(`Add New Story`)}</h4>
        <Row gutter={[16, 0]}>
          <Col span={24} sm={24}>
            <Form.Item
              label={lang(`Title`)}
              name="title"
              rules={[
                {
                  required: false,
                  message: lang("Title is required"),
                },
                {
                  max: 200,
                  message: lang(
                    "Title should not contain more than 200 characters!"
                  ),
                },
                {
                  min: 2,
                  message: lang("Title should contain at least 2 characters!"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={lang(`Enter Title`)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  validator: (_, value) => {
                    if (value !== undefined && value?.length > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(lang("Image is required")));
                  },
                },
              ]}
              name="image"
            >
              <SingleImageUpload
                value={image}
                fileType={FileType}
                imageType={"category"}
                btnName={lang("Story")}
                onChange={(data) => handleImage(data)}
                isDimension={false}
                size={5}
              />
            </Form.Item>
            {image && (
              <div className="mt-2 add-img-product">
                {typeof image === "string" &&
                supportedVideoExtensions?.some((ext) =>
                  image.toLowerCase().endsWith(ext)
                ) ? (
                  <video controls src={image} />
                ) : (
                  <Image width={120} src={image ?? notfound} />
                )}
              </div>
            )}
            <p className="img-size-details">
              **
              {lang(
                "Images should be all size accepted for best view in story. You can select only (.gif, .png, .jpeg, .jpg) format files upto 5 MB file size"
              )}
              ..!!!
            </p>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const ReadStory = ({ show, hide, refresh, data, provider }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const { userProfile } = useAuthContext();
  const supportedVideoExtensions = [".mp4", ".ogg", ".webm", ".mpeg"];
  const params = useParams()
  let isVideo = supportedVideoExtensions.some((ext) =>
    data?.image?.toLowerCase()?.endsWith(ext)
  );

  const [stories, setStories] = useState([
    {
      url: data?.image,
      duration: 5000,
      type: isVideo ? "video" : "image",
      header: {
        heading: data?.title,
        subheading: `Posted ${formatTimeAgo(data?.created_at)}`,
        profileImage: provider?.logo
          ? provider?.logo
          : provider?.selected_category_id?.image
          ? provider?.selected_category_id?.image
          : Prouser,
      },
    },
  ]);

  const handleDeleteStory = (id) => {
    setLoading(true);

    const payload = {
      ids: data?._id,
      provider_id : params.id
    };
    request({
      url: apiPath.deleteStory,
      method: "DELETE",
      data: payload,
      onSuccess: (data) => {
        ShowToast(data.message, Severty.SUCCESS);
        setLoading(false);
        hide();
        refresh();
      },
      onError: (error) => {
        console.log(error, "error");
        setLoading(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  return (
    <Modal
      open={show}
      okText={<DeleteOutlined />}
      cancelText={<ArrowRightOutlined className="main-back--button-csss" />}
      width={360}
      onCancel={() => {
        hide();
      }}
      onOk={() => {
        handleDeleteStory();
      }}
      centered
      okButtonProps={{
        form: "story",
        htmlType: "submit",
        loading: loading,
      }}
      className="full-width-modal tab_modal deleteWarningModal eleteWarningModal-hhht5657 eleteWarningModal-hhht5657-for-right"
    >
      <Form className="" id="story" form={form} layout="vertical">
        <Row gutter={[16, 0]} className="stories-container">
          <Col span={24} sm={24} className="mx-auto p-0">
            <Stories
              stories={stories.map((story) => ({
                ...story,
                content: () =>
                  story.type === "video" ? (
                    <video
                      src={story.url}
                      controls
                      autoPlay
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <>
                      <img
                        src={story.url}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          maxWidth: "100%",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "20px",
                          left: "20px",
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          overflow: "hidden",
                          border: "2px solid white",
                        }}
                      >
                        <img
                          src={story.header.profileImage}
                          alt="Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </>
                  ),
              }))}
              defaultInterval={3000}
              width={"100%"}
              className="stories"
            ></Stories>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Highlight;
