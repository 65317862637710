import React, { useEffect, useState } from "react";

import { Avatar, List, Skeleton , Button } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import InfiniteScroll from "react-infinite-scroll-component";
import lang from "../../helper/langHelper";
import Prouser from "../../assets/images/user.png";
import { Severty, ShowToast } from "../../helper/toast";
import ConfirmationBox from "../../components/ConfirmationBox";
import DeleteModal from "../../components/DeleteModal";
import { CheckCircleFilled, CheckCircleOutlined, CheckSquareOutlined, DeleteOutlined, EyeOutlined, FileMarkdownOutlined, MediumWorkmarkOutlined } from "@ant-design/icons";
import { useAppContext } from "../../context/AppContext";
import Header  from "../../components/layout/Header"
import CheckableTag from "antd/lib/tag/CheckableTag";

export const NotificationList = () => {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState("");
  const [show, setShow] = useState(false);
  const [showAllDelete, setShowAllDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const {language,setRefreshNotification} = useAppContext()
  
  const [pagination, setPagination] = useState({
    current: 1,
    total: 10,
  });

  const navigate = useNavigate();
  const { request } = useRequest();

  const dates = {
    today: moment(new Date()).format("YYYY-MM-DD"),
    yesterday: moment(new Date().getTime() - 24 * 60 * 60 * 1000).format(
      "YYYY-MM-DD"
    ),
  };

  const fetchData = () => {
    request({
      url:
        apiPath.notificationUser +
        `?status=${""}&page=${pagination ? pagination.current : 1}&pageSize=${
          pagination && pagination?.total ? pagination?.total : 10
        }&search=${""}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setList(data);
        data.length == 0 && setHasMore(false);
        setLoading(false);
      },
      onError: (err) => {
        console.log(err);
        setLoading(false);
      },
    });
  };

  const onNext = ({}) => {
    console.log("Call huaa next");
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [refresh, pagination]);

  const handleNavigate = (noti) => {
    console.log(noti, "useFor...");

    if (noti.use_for === "QUOTE") {
      navigate("/quotation-request");
    } else if (noti.use_for === "REPORT") {
      navigate("/reports");
    } else if (noti.use_for === "USER_SIGNUP") {
      navigate("/customer");
    } else if (noti.use_for === "VENDOR_SIGNUP") {
      navigate("/service-provider");
    }else if(noti.use_for ===  "NEW_SUPPORT_RECEIVED"){
      navigate("/support");
    }
   

    if (noti.use_for && !noti?.is_read) {
      request({
        url: `${apiPath.notification}/read/${noti?._id}`,
        method: "POST",
        onSuccess: ({ data }) => {
          ShowToast(lang("Notification Read Successfully"), Severty.SUCCESS);
          setRefresh((prev) => !prev); 
        
          setTimeout(() => {        
            setRefreshNotification(true)
          }, 20);
        },
        onError: (err) => {
          console.log(err);
        },
      });

      setTimeout(() => {
        fetchData();
      }, 2000);
    }
  };

  const deleteNotification = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.notification}/delete/${selected}`,
      method: "DELETE",
      onSuccess: ({ data, message }) => {
        ShowToast(message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
        setLoading(false);
        setSelected("");
        setTimeout(() => {        
          setRefreshNotification(true)
        }, 20);

      },
      onError: (err) => {
        ShowToast(err?.response?.data?.message, Severty.ERROR);
        console.log(err);
        setLoading(false);
      },
    });
  };

  const readAllNotifcation = () => {
    request({
      url: `${apiPath.notification}/read`,
      method: "POST",
      onSuccess: ({ data }) => {
        ShowToast(lang("Notification  Read All Successfully"), Severty.SUCCESS);
        setRefresh((prev) => !prev);
        setTimeout(() => {        
          setRefreshNotification(true)
        }, 20);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const markNotification = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.notification}/mark-done/${id}`,
      method: "PUT",
      onSuccess: ({ data, message }) => {
        ShowToast(message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
        setLoading(false);
        setSelected("");
        setTimeout(() => {        
          setRefreshNotification(true)
        }, 20);

      },
      onError: (err) => {
        ShowToast(err?.response?.data?.message, Severty.ERROR);
        console.log(err);
        setLoading(false);
      },
    });
  };

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <InfiniteScroll
            dataLength={list.length} //This is important field to render the next data
            next={onNext}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                {list.length == 0 ? (
                  <b>{lang("No notification")}</b>
                ) : (
                  <b>{lang("Yay! You have seen it all")}</b>
                )}
              </p>
            }
          >
                <div className="main-dltall-not-all">
                    <Button
                    disabled={!list?.length}
                      className="deleteall-main-button"
                      onClick={() => {
                        setShowAllDelete(true);
                      }}
                    >
                      {lang("Delete All")}
                    </Button>
                     <Button
                     disabled={!list?.length}
                      className="deleteall-main-button"
                      onClick={() => {
                        readAllNotifcation();
                      }}
                    >
                      {lang("Read All")}
                    </Button>
                  </div>
            {list?.map((data, index) => {
              console.log(data, "datadatadata");
              return (
                <div className="notification-card">
                
                  <h4 className="main-logo-header-ui98">
                    {moment(data._id).format("YYYY-MM-DD") === dates.today
                      ? lang("Today's Notifications")
                      : moment(data._id).format("YYYY-MM-DD") ===
                        dates.yesterday
                      ? lang("Yesterday's Notifications")
                      : moment(data._id).format("YYYY-MM-DD")}
                  </h4>

                  <List
                    key={"groupItem" + index}
                    itemLayout="horizontal"
                    dataSource={data.notifications}
                    renderItem={(item, index) => (
                      <List.Item 
                      className={`remamber-main ${item?.is_read ? "Notification-read" : "Notification-unread"}`}
                      >
                        <List.Item.Meta
                          
                          onClick={() => handleNavigate(item)}
                          avatar={<Avatar src={Prouser} />}
                          title={

                           <div className="main-time-for998">
                             <div> { language !== "en" && language !== null
                                ? item?.[`${language}_title`] ?? item?.title
                                : item?.title }</div>
                            <h6>
                                  {moment(item?.created_at).format("hh:mm a")}
                                </h6>
                           </div>
                          }
                          description={
                            <div
                            className="pt-1"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ display: "inline" }}>
                              
                                { language !== "en" && language !== null
                                ? item?.[`${language}_description`] ?? item?.description
                                : item?.description }
                                
                              </div>
                             
                          <div className="main-new-delete-button">
                          {!item?.is_mark_done ? <div
                                className="main-delete-out"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    markNotification(item?._id)
                                  }}
                                >
                                  <CheckCircleOutlined />
                                </div> : "" }
                               
                              
                                <div 
                                className="main-delete-out"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelected(item?._id);
                                    setShow(true);
                                  }}
                                >
                                  <DeleteOutlined />
                                </div>

                          </div>
                            </div>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </div>
              );
            })}
          </InfiniteScroll>
        </>
      )}
      {show && (
        <DeleteModal
          title={lang("Delete Notification")}
          subtitle={lang(`Are you sure you want to delete this notification?`)}
          show={show}
          hide={() => {
            setShow(false);
            setSelected("");
          }}
          onOk={(value) => deleteNotification(value)}
        />
      )}
      {showAllDelete && (
        <DeleteModal
          title={lang("Delete All Notification")}
          subtitle={lang(`Are you sure you want to delete all notification?`)}
          show={showAllDelete}
          hide={() => {
            setShowAllDelete(false);
            setSelected("");
          }}
          onOk={(value) => {
            setSelected();
            deleteNotification();
          }}
        />
      )}
    </>
  );
};
