import { Button, Image, Input, Switch, Table, Tooltip, Select } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UndoOutlined } from "@ant-design/icons";
import notfound from "../../assets/images/not_found.png";
import Plus from "../../assets/images/plus.svg";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import EditIcon from "../../assets/images/edit.svg";
import DeleteModal from "../../components/DeleteModal";
import AddProviderService from "../../modals/AddProviderService";


function Index() {
  const heading = lang("Provider Services") + " " + lang("Management");
  const { setPageHeading, country } = useContext(AppStateContext);

  const sectionName = "Provider Service";
  const routeName = "service";
  const params = useParams();

  const api = {
   
    addEdit: apiPath.addEditProviderService,
    list: apiPath.listProviderService,
    services: apiPath.allServices,
   
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const [showMessage, setShowMessage] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [totalSrc, setTotalSrc] = useState(0);
  const navigate = useNavigate()



 

  const [filter, setFilter] = useState({
    service_id : undefined,

  });

  const columns = [
    {
      title: lang("UID"),
      dataIndex: "uid",
      key: "uid",
      sorter: (a, b) => a?.uid?.localeCompare(b?.uid),
      sortDirections: ["ascend", "descend"],
      render: (_, { uid }) => {
        return uid ? <span className="cap">{uid}</span> : "-";
      },
    },
    {
      title: lang("Thumbnail"),
      dataIndex: "image",
      key: "image",
      render: (_, { cover_image }) => (
        <Image
          width={50}
          src={cover_image ? cover_image : notfound}
          className="table-img"
        />
      ),
    },
    {
      title: lang("Service Name"),
      dataIndex: "service_id",
      key: "service_id",
      sorter: (a, b) => a?.service_id?.name?.localeCompare(b?.service_id?.name),
      sortDirections: ["ascend", "descend"],
      render: (_, { service_id }) => {
        return service_id ? <span className="cap">{service_id?.name}</span> : "-";
      },
    },
    // {
    //   title: lang("Name"),
    //   dataIndex: "name",
    //   key: "name",
    //   sorter: (a, b) => a?.name?.localeCompare(b?.name),
    //   sortDirections: ["ascend", "descend"],
    //   render: (_, { name }) => {
    //     return name ? <span className="cap">{name}</span> : "-";
    //   },
    // },
  
    {
      title: lang("Discount Price (JOD)"),
      dataIndex: "discount_price",
      key: "discount_price",
      sorter: (a, b) => (a?.discount_price || 0) - (b?.discount_price || 0),
      sortDirections: ["ascend", "descend"],
      render: (_, { discount_price }) => {
        return discount_price ? <span className="cap">{discount_price ?? 0}</span> : "-";
      },
    },
    {
      title: lang("Original Price (JOD)"),
      dataIndex: "original_price",
      key: "original_price",
      sorter: (a, b) => (a?.original_price || 0) - (b?.original_price || 0),
      sortDirections: ["ascend", "descend"],
      render: (_, { original_price }) => {
        return original_price ? <span className="cap">{original_price ?? 0}</span> : "-";
      },
    },
    {
      title: lang("Created On"),
      key: "created_at",
      dataIndex: "created_at",
      // sorter: (a, b) => a?.created_at?.localeCompare(b?.created_at),
      // sortDirections: ["ascend", "descend"],
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MMM-YYYY");
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <Tooltip
              title={lang("Edit")}
              color={"purple"}
              key={"update" + routeName}
            >
              <Button
                title={lang("Edit")}
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  setSelected(record);
                  setVisible(true);
                }}
              >
                <img src={EditIcon} />
                <span>{lang("Edit")}</span>
              </Button>
            </Tooltip>

            <Tooltip title={lang("Delete")} color={"purple"} key={"Delete"}>
              <Button
                title={lang("Delete")}
                className="btnStyle deleteDangerbtn"
                onClick={() => {
                  if (record.have_item) {
                    return setShowMessage(true);
                  }
                  setSelected(record);
                  showDeleteModal(true);
                }}
              >
                <img src={deleteWhiteIcon} />
                <span>{lang("Delete")}</span>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    
  ];

  useEffect(() => {
    if(!params.id) return
    setLoading(true);
    fetchData(pagination);
  }, [refresh, debouncedSearchText,  filter , params.id]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters, sorter) => {
    const filterActive = filters ? filters.is_active : null;
    const queryString = Object.entries(filter)
      .filter(([_, v]) => v != undefined)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    request({
      url:`${api.list}/${params.id ? params.id : ""}?status=${filterActive ? filterActive.join(",") : ""}&page=${
          pagination ? debouncedSearchText ? 1 : pagination.current : 1
        }&pageSize=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setLoading(false);
        setList(data?.data ?? []);
        setShowWarning(data?.completeSrcWarning ?? false) 
        setTotalSrc(data?.totaladdSrc ?? 0)
        setPagination((prev) => ({
          ...prev,
          current: pagination.current,
          total: data.totalDocs,
        }));
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };


  const onDelete = (id) => {
    request({
      url: api.addEdit + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
         ShowToast(data.message, Severty.SUCCESS);
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error,"error");
        setLoading(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    let query = undefined;
    if (field && order) {
      query = `${field}=${order}`;
      console.log(query);
    }
    fetchData(pagination, filters, query);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };


  const handleAddSrc = () =>{
    if(showWarning){
      setShowMessage(true);
   
    }else {
      setVisible(true);
      setSearchText("");
    }
  }
 

 

  return (
    <>
      <SectionWrapper
        cardHeading={lang(`All Services`)}
        cardSubheading={lang(`${lang("You can only add upto")}  ${totalSrc ?? 0} ${lang("services")}.`)}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              <div className="pageHeadingSearch">
                <Input.Search
                  className="searchInput"
                  placeholder={lang("Search by Service name")}
                  onChange={onSearch}
                  allowClear
                />
              </div>
              <Button
                className="primary_btn btnStyle"
                onClick={(e) => {
                  handleAddSrc()
               
                }}
              >
                <span className="add-Ic">
                  <img src={Plus} />
                </span>
                {lang("Add New Service")}
              </Button>
              <Button
                type="primary"
                onClick={(e) => navigate(-1)}
           
              >
                {lang("Back")}
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination checkBoxSrNo">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{ 
              current: pagination?.current,    
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AddProviderService
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
       {deleteModal && (
        <DeleteModal
          reasons={[]}
          title={lang("Delete Service")}
          subtitle={lang(`Are you sure you want to Delete this Service?`)}
          show={deleteModal}
          hide={() => {
            showDeleteModal(false);
            setSelected();
          }}
          onOk={() => onDelete(selected?._id)}
        />
      )}

    {showMessage && (
        <DeleteModal
          title={lang("Warning")}
          subtitle={lang(
            `You can only add upto  ${totalSrc ?? 0} services .`
          )}
          show={showMessage}
          hide={() => {
            setShowMessage(false);
          }}
          onOk={() => setShowMessage(false)}
          reasons={[]}
        />
      )}
    </>
  );
}

export default Index;
