import {
  Button,
  Card,
  Col,
  Image,
  Row,
  Select,
  Table,
  Tabs,
  Tooltip,
  Input,
  Switch,
  Space,
} from "antd";
import moment from "moment";
import { UndoOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import notfound from "../../assets/images/not_found.png";
import Plus from "../../assets/images/plus.svg";
import ConfirmationBox from "../../components/ConfirmationBox";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import {
  BlockDriver,
  Last20Years,
  Months,
  attributeOption,
} from "../../constants/var";
import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import DeleteModal from "../../components/DeleteModal";
import AddForm from "./AddForm";
import EditIcon from "../../assets/images/edit.svg";
import ViewLogs from "../../components/ViewLogs";
import { DeleteSubadmin } from "../../constants/reasons";
import { DownloadExcel } from "../../components/ExcelFile";
import { kMaxLength } from "buffer";

const { TabPane } = Tabs;

function Index() {
  const heading = lang("Service Attribute") + " " + lang("Management");
  const { setPageHeading, country } = useContext(AppStateContext);

  const sectionName = "Service Attribute";
  const routeName = "attributes";
  const params = useParams();

  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");

  const api = {
    list: apiPath.listAttribute,
    status: apiPath.statusAttribute,
    addEdit: apiPath.listAttribute,
    filterServices: apiPath.allServices,
  };

  const [searchText, setSearchText] = useState("");

  const { request } = useRequest();
  const navigate = useNavigate();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [logs, showLogs] = useState(false);
  const [selected, setSelected] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("all");
  const debouncedSearchText = useDebounce(searchText, 300);
  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [showMessage, setShowMessage] = useState(false);
  const [countries, setCountries] = useState([]);
  const [showStatusMessage, setShowStatusMessage] = useState(false);
  const [paramsIds, setParamsIds] = useState({});
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [categories, setCategory] = useState([]);
  const [services, setServices] = useState([]);
  const { language } = useAppContext();
  const [checkStrictly, setCheckStrictly] = useState(false);
  const [allStatusChangeModal, setAllStatusChangeModal] = useState(false);
  const [statusKey, setStatusKey] = useState("");

  const [filter, setFilter] = useState({
    country_id: undefined,
    year: undefined,
    category: undefined,
    service_id: undefined,
  });

  const onDelete = (id) => {
    request({
      url: api.addEdit + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChangeStatus = (id, message) => {
    request({
      url: api.status + "/" + id,
      method: "PUT",
      data: { message },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
        ShowToast(data.message, Severty.SUCCESS);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const statusChangeAll = () => {
    if (!selectedIds.length) return;
    request({
      url: api.list + `/status-change-all?key=${statusKey ? statusKey : ""}`,
      method: "POST",
      data: {
        ids: selectedIds,
      },
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
        setSelectedIds([]);
        setStatusKey("");
      },
      onError: (error) => {
        setStatusKey("");
        console.log(error);
        setLoading(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  const columns = [
    {
      title: lang("Name"),
      dataIndex: "name",
      key: "name",
      width: 200,
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      sortDirections: ["ascend", "descend"],
      render: (_, { name }) =>
        name ? <span className="cap">{name}</span> : "-",
    },
    {
      title: lang("Arabic Name"),
      dataIndex: "ar_name",
      key: "ar_name",
      width: 200,
      sorter: (a, b) => a?.ar_name?.localeCompare(b?.ar_name),
      sortDirections: ["ascend", "descend"],
      render: (_, { ar_name }) =>
        ar_name ? <span className="cap">{ar_name}</span> : "-",
    },
    {
      title: lang("Type"),
      dataIndex: "type",
      key: "type",
      width: 200,
      sorter: (a, b) => a?.type?.localeCompare(b?.type),
      sortDirections: ["ascend", "descend"],
      render: (_, { type }) =>
        type ? <span className="cap">{type}</span> : "-",
    },
    {
      title: lang("Category"),
      dataIndex: "category_id",
      key: "category_id",
      width: 200,
      sorter: (a, b) =>
        a?.category_id?.name?.localeCompare(b?.category_id?.name),
      sortDirections: ["ascend", "descend"],
      render: (_, { category_id }) =>
        category_id ? <span className="cap">{language !== "en" && language !== null
      ? category_id?.[`${language}_name`] ?? category_id?.name
      : category_id?.name  ?? "-"}</span> : "-",
    },
    Table.EXPAND_COLUMN,
    {
      title: lang("Service"),
      dataIndex: "service_id",
      key: "service_id",
      width: 200,

      render: (_, { service_id }) =>
        service_id?.length ? <span className="cap new-main-ellipes-class"><Tooltip placement="topLeft" title={[service_id?.map(({ name }) => name)?.join(", ")]}>[{service_id?.map((item) => language !== "en" && language !== null
      ? item?.[`${language}_name`] ?? item?.name
      : item?.name)?.join(", ")}]</Tooltip>  </span> : "-",
    },
    {
      title: lang("options"),
      dataIndex: "options",
      key: "options",
      width: 200,
      render: (_, { options }) =>
        options?.length ? <span className="cap new-main-ellipes-class"><Tooltip placement="topLeft" title={[options?.map(({ name }) => name)?.join(", ")]}>[{options?.map((item) => language !== "en" && language !== null
      ? item?.[`${language}_name`] ?? item?.name
      : item?.name)?.join(", ")}]</Tooltip></span> : "-",
    },
    {
      title: lang("Attribute Sort Order"),
      dataIndex: "sort",
      key: "sort",
      width: 200,
      render: (_, { sort }) =>
        sort ? <span className="cap">{sort}</span> : "-",
    },
    {
      title: lang("Register Date"),
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      key: "action",
      render: (_, record) => {
        return (
          <div className="action-buttons-containermain">
            <Tooltip
              title={lang("Edit")}
              color={"purple"}
              key={"Edit" + routeName}
              className="main-second-classforedit-5"
            >
              <Button
                title={lang("Edit")}
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  setSelected(record);
                  setVisible(true);
                }}
              >
                <img src={EditIcon} />
                <span>{lang("Edit")}</span>
              </Button>
            </Tooltip>
            <Tooltip
              title={lang("Delete")}
              color={"purple"}
              key={"delete" + routeName}
            >
              <Button
                className="delete-cls"
                title={lang("Delete")}
                onClick={() => {
                  if (record.have_item) {
                    return setShowMessage(true);
                  }
                  setSelected(record);
                  setShowDelete(true);
                }}
              >
                <i className="fa fa-light fa-trash"></i>
                <span>{lang("Delete")}</span>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: lang("Status"),
      key: "is_active",
      dataIndex: "is_active",
      filters: [
        {
          text: "Active",
          value: "true",
        },
        {
          text: "InActive",
          value: "false",
        },
      ],
      render: (_, { _id, is_active, have_active_item }) => {
        return (
          <Switch
            onChange={() => {
              if (have_active_item) {
                return setShowStatusMessage(true);
              }
              handleChangeStatus(_id);
            }}
            checked={is_active}
          />
        );
      },
    },
  ];

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCategory(data);
      },
    });
  };

  const getFilterServices = (id) => {
    request({
      url: `${api.filterServices}/${id}`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        setServices(data);
      },
    });
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);

    getFilter();
  }, [refresh, debouncedSearchText, filter, country.country_id]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    request({
      url:
        api.list +
        `/?status=${filterActive ? filterActive.join(",") : ""}&page=${
          pagination ? pagination.current : 1
        }&pageSize=${pagination.pageSize ?? 10}&search=${debouncedSearchText}${
          queryString ? `&${queryString}` : ""
        }${path ? `&status=${path}` : ""}`,
      method: "GET",
      onSuccess: ({ data, status, total }) => {
        setLoading(false);
        console.log(data, "data11");
        if (status) {
          // setList(data.docs);
          setSelectedIds([]);
          setList(() => {
            return data.docs.map((item) => {
              return {
                ...item,
                key: item._id,
              };
            });
          });
          setPagination((prev) => ({
            pageSize: pagination.pageSize,
            current: pagination.current,
            total: data.totalDocs,
          }));
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1, pageSize: pagination.pageSize });
  };

  const getExportData = async () => {
    try {
      const queryString = Object.entries(filter)
        .filter(([_, v]) => v != undefined)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");
      setExportLoading(true);
      request({
        url:
          api.list +
          `?page=${pagination ? pagination.current : 1}&pageSize=${
            pagination.pageSize ?? 10
          }&search=${debouncedSearchText}${
            queryString ? `&${queryString}` : ""
          }${path ? `&status=${path}` : ""}`,
        method: "GET",
        onSuccess: ({ data, status, total, message }) => {
          setExportLoading(false);
          if (status) {
            excelData(data.docs ?? []);
          }
        },
        onError: (error) => {
          console.log(error);
          setExportLoading(false);
          ShowToast(error, Severty.ERROR);
        },
      });
    } catch (err) {
      console.log(err);
      setExportLoading(false);
    }
  };

  const excelData = (exportData) => {
    if (!exportData.length) return;

    const data = exportData.map((row) => ({
      Name: row?.name || "-",
      "Arabic Name": row?.ar_name || "-",
      Category: row?.category_id ? row?.category_id.name : "-",
      Services: row?.service_id
        ? row.service_id.map((item) => item.name).join(" , ")
        : "-",
      Type: row?.type ? row?.type : "-",
      Sort: row?.sort ? row?.sort : "-",
      Options: row?.options?.length
        ? row?.options?.map((option) => option?.name).join(" , ")
        : "",
      "Arabic Options": row?.options?.length
        ? row?.options?.map((option) => option?.ar_name).join(" , ")
        : "",
      "Registered On": moment(row.created_at).format("DD-MM-YYYY"),
    }));
    DownloadExcel(data, sectionName);
  };

  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedIds(selectedRowKeys);
      console.log(selectedRowKeys, "selectedRowKeys");
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
      checked: selectedIds.includes(record.key),
    }),
  };

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <SectionWrapper
                cardHeading={lang("All Attributes")}
                cardSubheading={""}
                extra={
                  <>
                    {/* <div className="button_group justify-content-end w-100"> */}
                    <div className="w-100 text-head_right_cont">
                      <div className="pageHeadingSearch">
                        <Input.Search
                          className="searchInput"
                          placeholder={lang("Search by attribute name")}
                          onChange={onSearch}
                          allowClear
                          value={searchText}
                        />
                      </div>
                      <div className="role-wrap">
                        <Select
                          last20Years
                          width="110px"
                          placeholder={lang("Type")}
                          showSearch
                          value={filter?.type}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={attributeOption?.map((item) => ({
                            value: item.value,
                            label: item.label,
                          }))}
                          onChange={(value) => onChange("type", value)}
                        />
                      </div>
                      <div className="role-wrap">
                        <Select
                          last20Years
                          width="110px"
                          placeholder={lang("Category")}
                          showSearch
                          value={filter.category_id}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={categories.map((item) => ({
                            value: item._id,
                            label:
                              language !== "en" && language !== null
                                ? item?.[`${language}_name`] ?? item?.name
                                : item?.name,
                          }))}
                          onChange={(value) => {
                            onChange("category_id", value);
                            getFilterServices(value);
                            setFilter((prev) => ({
                              ...prev,
                              service_id: undefined,
                            }));
                          }}
                        />
                      </div>
                      <div className="role-wrap">
                        <Select
                          last20Years
                          width="110px"
                          placeholder={lang("Service")}
                          showSearch
                          value={filter?.service_id}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={services?.map((item) => ({
                            value: item._id,
                            label:
                              language !== "en" && language !== null
                                ? item?.[`${language}_name`] ?? item?.name
                                : item?.name,
                          }))}
                          onChange={(value) => onChange("service_id", value)}
                        />
                      </div>
                      <Button
                        onClick={() => {
                          setFilter({
                            country_id: undefined,
                            service_id: undefined,
                            category_id: undefined,
                            type: undefined,
                          });
                          setServices([]);
                          setSearchText("");
                        }}
                        type="primary"
                        icon={<UndoOutlined />}
                      >
                        {lang("Reset")}
                      </Button>
                      <div className="btn_grp">
                        <Button
                          loading={exportLoading}
                          onClick={() => getExportData()}
                          className="primary_btn btnStyle"
                        >
                          {lang("Export to Excel")}
                        </Button>
                      </div>
                    </div>
                  </>
                }
              >
                <div className="button-sec">
                  <Button
                    disabled={!selectedIds.length}
                    className="btnStyle btnOutlineDelete"
                    onClick={() => {
                      if (!selectedIds.length) return;
                      setAllStatusChangeModal(true);
                      setStatusKey("active");
                    }}
                  >
                    {lang("Activate All")}
                  </Button>
                  <Button
                    disabled={!selectedIds.length}
                    className="btnStyle btnOutlineDelete"
                    onClick={() => {
                      if (!selectedIds.length) return;
                      setAllStatusChangeModal(true);
                      setStatusKey("inactive");
                    }}
                  >
                    {lang("Deactivate All")}
                  </Button>
                  <Button
                    className="ms-sm-2 primary_btn btnStyle"
                    onClick={(e) => {
                      setVisible(true);
                      setParamsIds(params);
                    }}
                  >
                    <span className="add-Ic">
                      <img src={Plus} />
                    </span>
                    {lang("Add")} {lang("Attribute")}
                  </Button>
                </div>
                <div className="table-responsive customPagination">
                  <Table
                    loading={loading}
                    columns={columns}
                    rowSelection={rowSelection}
                    dataSource={list}
                    pagination={{
                      current: pagination?.current,
                      defaultPageSize: 10,
                      responsive: true,
                      total: pagination.total,
                      showSizeChanger: true,
                      showQuickJumper: true,
                    }}
                    expandable={{
                      expandedRowRender: (record) => (
                        <div className="sec-add-table">
                        <p  style={{  margin: 0,  }}   >
                        <span>{lang("Services")} :</span>   {record.service_id?.length ? <span className="cap">[{record?.service_id?.map((item) => language !== "en" && language !== null
                       ? item?.[`${language}_name`] ?? item?.name
                        : item?.name)?.join(", ")}]  </span> : "No Services"}
                        </p>
                        <p  style={{  margin: 0,  }}   >
                        <span>{lang("Options")} :</span>  {record.options?.length  ? <span className="cap">[{record?.options?.map((item) => language !== "en" && language !== null
                        ? item?.[`${language}_name`] ?? item?.name
                        : item?.name)?.join(", ")}]  </span> : "-"}
                        </p>
                        </div>
                      ),
                    }}
                    onChange={handleChange}
                    className="ant-border-space"
                  />
                </div>
              </SectionWrapper>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && (
        <AddForm
          section={sectionName}
          api={api}
          show={visible}
          paramsIds={paramsIds}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {showMessage && (
        <DeleteModal
          title={lang("Delete Attribute")}
          subtitle={lang(
            "This Attribute contains Quote Template, please remove  this  from Quote Template before deleting Attribute"
          )}
          show={showMessage}
          hide={() => {
            setShowMessage(false);
          }}
          onOk={() => setShowMessage(false)}
          reasons={[]}
        />
      )}
      {showStatusMessage && (
        <DeleteModal
          title={lang("Inactive Attribute")}
          subtitle={lang(
            "This Attribute contains  Active Quote Template, please inactive Quote Template before deactivating  Attribute"
          )}
          show={showStatusMessage}
          hide={() => {
            setShowStatusMessage(false);
          }}
          onOk={() => setShowStatusMessage(false)}
          reasons={[]}
        />
      )}
      {showDelete && (
        <DeleteModal
          title={lang("Delete Attribute")}
          subtitle={lang(`Are you sure you want to Delete this Attribute?`)}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelected();
          }}
          onOk={() => onDelete(selected?._id)}
          reasons={DeleteSubadmin}
        />
      )}

      {showStatus && (
        <DeleteModal
          title={`${
            selected?.is_active ? lang(`Suspend`) : lang(`Reactive`)
          } ${lang("Drive")}`}
          subtitle={`${lang("Are you sure you want to")} ${
            selected?.is_active ? lang(`suspend`) : lang(`reactivate`)
          } ${lang("this user ?")}`}
          show={showStatus}
          hide={() => {
            setShowStatus(false);
            setSelected();
          }}
          reasons={selected?.is_active ? BlockDriver : []}
          onOk={(message) => handleChangeStatus(selected?._id, message)}
        />
      )}
      {allStatusChangeModal && (
        <DeleteModal
          title={
            statusKey === "inactive"
              ? lang("Deactivate All")
              : lang("Activate All")
          }
          subtitle={lang(
            `Are you sure you want to ${
              statusKey === "inactive" ? "Deactivate" : "Activate"
            } all attributes?`
          )}
          show={allStatusChangeModal}
          hide={() => {
            setAllStatusChangeModal(false);
            setSelectedIds([]);
            setStatusKey("");
          }}
          onOk={() => statusChangeAll()}
        />
      )}

      {logs && (
        <ViewLogs
          data={selected}
          show={logs}
          hide={() => {
            showLogs(false);
            setSelected();
          }}
        />
      )}
    </>
  );
}

export default Index;
