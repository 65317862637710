import lang from "../helper/langHelper";

export const Last20Years = Array.from({ length: 20 }, (_, index) =>
  (new Date().getFullYear() - index).toString(),
);
export const Months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const BlockRest = [
  "Quality and Safety Issues",
  "Chronic Order Delays",
  "Misrepresentation",
  "Violation of Platform Policies",
  "Illegal Activities",
];

export const BlockDriver = [
  "Consistent Order Delays",
  "Poor Customer Service",
  "Reckless Driving",
  "Fraudulent Activity",
  "Violation of Platform Policies",
];

export const CancelOrder = [
  "Payment Issues",
  "Unavailability of Items",
  "Delivery Address Inaccuracy",
  "Security or Fraud Concerns",
  "Violation of Platform Policies",
  "Customer Not Accept",
  "Customer Absent",
];

export const BlockSubAdmin = [
  "Violation of Company Policies",
  "Breach of Security Protocols",
  "Misuse of Administrative Privileges",
  "Failure to Fulfill Assigned Responsibilities",
  "Engagement in Unethical Behavior",
];

export const DeleteBanner = [
  "Expired Promotion or Event",
  "Incorrect Information",
  "Inappropriate Content",
  "Rebranding or Campaign Change",
  "Design or Layout Issues",
];

export const rolesOptions = [
  { name: "dashboard-management", label: lang("Dashboard Management") },
  { name: "customer-manager", label: lang("Customer Management") },
  { name: "profile-manager", label: lang("SP Profile Management") },
  { name: "category-management", label: lang("Category Management") },
  { name: "sub-category-management", label: lang("Sub Category Management") },
  { name: "service-manager", label: lang("Service Management") },
  { name: "attribute-manager", label: lang("Attribute Management") },
  { name: "provider-manager", label: lang("Service Provide Management") },
  { name: "event-manager", label: lang("Event Type Management") },
  { name: "quotation-request", label: lang("Quotation Request Management") },
  { name: "cms-manager", label: lang("CMS Management") },
  { name: "blog-manager", label: lang("blog Management") },
  { name: "testimonial-manager", label: lang("Testimonial Management") },
  { name: "report-manager", label: lang("Reports Management") },
  { name: "invitation-template-manager", label: lang("Invitation Template Management") },
  // { name: "finance-manager", label: "Financial Management" },
  // { name: "collector-manager", label: "Collection Management" },
  // { name: "service-location-manager", label: "Service Location  Management" },
  // { name: "delivery-charge-manager", label: "Delivery charge  Management" },
  { name: "Notifications", label: lang("Notifications Management") },
  { name: "email-template-manager", label: lang("Email Template Management") },
];

export const Palestine = {
  id: "646b2e0f46865f1f65565346",
  name: "Palestine",
};

export const ProfileOptions = [
  // General---->
  {
    name: "name",
    label: "Business Name",
    is_selected: true,
    is_required: true,
    is_disable: true,
  },
  {
    name: "ar_name",
    label: "Business Name Arabic",
    is_selected: false,
    is_required: false,
    is_disable: false,
  },
  {
    name: "description",
    label: "Business Description",
    is_selected: true,
    is_required: true,
    is_disable: true,
  },
  {
    name: "ar_description",
    label: "Business Description Arabic",
    is_selected: false,
    is_required: false,
    is_disable: false,
  },

  { name: "country_id", label: "Country", is_selected: true, is_required: true, is_disable: true},
  { name: "city_id", label: "City", is_selected: true, is_required: true, is_disable: true},

  {
    name: "sub_category_id",
    label: "Sub Category Name",
    is_selected: true,
    is_required: false,
    is_disable: false,
  },
  {
    name: "associated_manager",
    label: "Associated manager",
    is_selected: false,
    is_required: false,
    is_disable: false,
  },
  
  // { name: "address", label: "Address", is_selected: false, is_required: false, is_disable: false},
  // { name: "ar_address",label: "Address Arabic",is_selected: false,is_required: false, is_disable: false},

  // Contact----->
  {
    name: "contact_person_name",
    label: "Contact Person Name",
    is_selected: true,
    is_required: true,
    is_disable: true,
  },
  {
    name: "email",
    label: "Email ID",
    is_selected: true,
    is_required: true,
    is_disable: true,
  },
  {
    name: "mobile",
    label: "Contact Person Phone Number",
    is_selected: true,
    is_required: true,
    is_disable: true,
  },
  {
    name: "password",
    label: "Create Password",
    is_selected: false,
    is_required: false,
    is_disable: false,
  },

  // Media---->
  {
    name: "website_url",
    label: "Website Url",
    is_selected: false,
    is_required: false,
    is_disable: false,
  },
  {
    name: "logo",
    label: "Upload Logo",
    is_selected: false,
    is_required: false,
    is_disable: false,
  },
 

  {
    name: "image",
    label: "Upload Image",
    is_selected: false,
    is_required: false,
    is_disable: false,
  },
  {
    name: "cover_photo",
    label: "Upload thumbnail",
    is_selected: false,
    is_required: false,
    is_disable: false,
  },
  {
    name: "location",
    label: "Location",
    is_selected: false,
    is_required: false,
    is_disable: false,
  },
  {
    name: "working_days",
    label: "Working day's",
    is_selected: false,
    is_required: false,
    is_disable: false,
  },

  // Event ----->
  {
    name: "eventtype_id",
    label: "Event",
    is_selected: false,
    is_required: false,
    is_disable: false,
  },

  // offerrings---->

  // { name: "category-name", label: "Category Name" ,is_selected:false,is_required:false},
  {
    name: "service_id",
    label: "Service Name",
    is_selected: true,
    is_required: true,
    is_disable: true,
  },
  {
    name: "packages",
    label: "Package",
    is_selected: true,
    is_required: false,
    is_disable: false,
  },

  // {
  //   name: "images_per_service",
  //   label: "Number of Images per service",
  //   is_selected: false,
  //   is_required: false,
  //   is_disable: false,
  // },
  // {
  //   name: "number_of_service",
  //   label: "Number of services",
  //   is_selected: false,
  //   is_required: false,
  //   is_disable: false,
  // },

];



export const attributeOption = [
  {
    label: lang("Package"),
    value: "package",
  },
  {
    label: lang("Dropdown"),
    value: "dropdown",
  },
  {
    label: lang("Radio"),
    value: "radio",
  },
  {
    label: lang("Button"),
    value: "button",
  },
  {
    label: lang("Checkbox"),
    value: "checkbox",
  },
  {
    label: lang("Input Number"),
    value: "inputNumber",
  },
  {
    label: lang("Calendar"),
    value: "calendar",
  },
  // {
  //   label: "File",
  //   value: "file",
  // },
  {
    label: lang("Color"),
    value: "color",
  },
  {
    label: lang("Boolean"),
    value: "boolean",
  },
  {
    label: lang("Textbox"),
    value: "textbox",
  },
];
