import { Button, Image, Input, Switch, Table, Tooltip, Select, DatePicker } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UndoOutlined } from "@ant-design/icons";
import notfound from "../../assets/images/not_found.png";

import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";

import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";

import EditIcon from "../../assets/images/edit.svg";
import { DownloadExcel } from "../../components/ExcelFile";
import { kMaxLength } from "buffer";

function Index() {
  const heading = lang("Support") + " " + lang("Management");
  const { setPageHeading, country } = useContext(AppStateContext);



  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate()
  
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const [exportLoading, setExportLoading] = useState(false);


  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    start_date: undefined,
    end_date: undefined,
    status: undefined,
  });

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const columns = [
   

    {
      title: lang("Name"),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a?.first_name?.localeCompare(b?.first_name),
      sortDirections: ["ascend", "descend"],
      render: (_, { first_name , last_name}) => {
        return  first_name && last_name ? <span className="cap">{`${first_name} ${last_name}`}</span> : "-";
      },
    },
    {
      title: lang("Email"),
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      sortDirections: ["ascend", "descend"],
      render: (_, { email }) => {
        return email ? <span className="cap">{email}</span> : "-";
      },
    },
    {
      title: lang("Mobile Number"),
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (_, { mobile_number , country_code}) => {
        return  country_code && mobile_number ? <span className="cap">{`+${country_code}-${mobile_number}`}</span> : "-";
      },
    },
  
    {
      title: lang("reported On"),
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MMM-YYYY");
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      render: (_, record) => {
        return (
          <>
            <Tooltip
              title={lang("View Detail")}
              color={"purple"}
              key={"viewDetail"}
            >
              <Button className="ms-sm-2 mt-xs-2 btnStyle btnOutlineDelete" onClick={()=>navigate(`/support/${record?._id}`)}>
                {lang("View Detail")}
              </Button>
            </Tooltip>
          </>
        );
      },
    },
   
   
  ];

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
  }, [refresh, debouncedSearchText, country.country_id, filter]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;
    const queryString = Object.entries(filter)
      .filter(([_, v]) => v != undefined)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    request({
      url:
        apiPath.supportList +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${
          pagination ? pagination.current : 1
        }&limit=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setLoading(false);
        setList(() => {
          return data.docs.map((item) => {
            return {
              ...item,
              key: item._id,
            };
          });
        });
        setPagination((prev) => ({
          ...prev,
          current: pagination.current,
          total: data.totalDocs,
        }));
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };


  const getExportData = async () => {
    try {
   

      setExportLoading(true);
      request({
        url:
        apiPath.supportList +
          `?page=${pagination ? pagination.current : 1
          }&limit=${pagination ? pagination.total : 100000
          }`,
        method: "GET",
        onSuccess: ({ data, status, total, message }) => {
          setExportLoading(false);
          if (status) {
            excelData(data.docs ?? []);
          }
        },
        onError: (error) => {
          console.log(error);
          setExportLoading(false);
          ShowToast(error, Severty.ERROR);
        },
      });
    } catch (err) {
      console.log(err);
      setExportLoading(false);
    }
  };

  const excelData = (exportData) => {
    console.log(exportData, "exportData")
    if (!exportData.length) return;

    const data = exportData.map((row) => ({
      "Name": row?.first_name && row?.last_name ? `${row?.first_name} ${row?.last_name}` : "-",
      "Email": row?.email ? row?.email : "-",
      "Mobile Number": row?.country_code && row?.mobile_number ? `+${row?.country_code}-${row?.mobile_number}` : "-",
      "Message" :  row?.message ? row?.message : "-",
      "Reported On": moment(row.created_at).format("DD-MM-YYYY"),
    }));
    DownloadExcel(data, "supports");
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang(`All Support's`)}
        extra={
          <div className="tab-upload-wrap d-flex align-items-center justify-content-between">
        <div className="pageHeadingSearch">
                  <Input.Search
                    value={searchText}
                    className="searchInput"
                    placeholder={lang(
                      "Search by name, email or number"
                    )}
                    onChange={onSearch}
                    allowClear
                  />
                </div>
                <div className="role-wrap">
                  <DatePicker.RangePicker
                    disabledDate={(current) => {
                      return current && current > moment().endOf("day");
                    }}
                    placeholder={[lang("Start Date"), lang("End Date")]}
                    value={[
                      filter.start_date ? moment(filter.start_date) : undefined,
                      filter.end_date ? moment(filter.end_date) : undefined,
                    ]}
                    onChange={(value) => {
                      if (value) {
                        setFilter((prev) => ({
                          ...prev,
                          start_date: moment(value[0]).format("YYYY-MM-DD"),
                          end_date: moment(value[1]).format("YYYY-MM-DD"),
                        }));
                      } else {
                        setFilter((prev) => ({
                          ...prev,
                          start_date: undefined,
                          end_date: undefined,
                        }));
                      }
                    }}
                  />
                </div>
                <div className="role-wrap"><Button
                  onClick={() => {
                    setFilter({                    
                      start_date: undefined,
                      end_date: undefined,
                   
                    });
                    setSearchText("");
                    
                  }}
                  type="primary"
                  icon={<UndoOutlined />}
                >
                  {lang("Reset")}
                </Button></div>
          <Button
                  loading={exportLoading}
                  onClick={() => getExportData()}
                  className="primary_btn btnStyle"
                >
                  {lang("Export to Excel")}
                </Button>
                
        </div>
        }
      >
        <div className="table-responsive customPagination withOutSearilNo">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>
    </>
  );
}

export default Index;
