import { Row, Col, Card, Table, DatePicker } from "antd";

import React, { useState, useEffect } from "react";

import moment from "moment";
import lang from "../../helper/langHelper";
const { RangePicker } = DatePicker;

function Index({data,loading}) {
  const [list, setList] = useState(data);
  const [pagination, setPagination] = useState(5);

  useEffect(() => {
    if (!data) return;
    setList(data);
    setPagination(data?.length);
    console.log("data ::", data);
  }, [data]);

  const columns = [
 

    {
      title: lang("Service Name"),
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => {
        return name ? <span className="cap">{name}</span> : "-";
      },
    },
    {
      title: lang("Category Name"),
      dataIndex: "category_id",
      key: "category_id",
      render: (_, { category_id }) => {
        console.log("category_id", category_id);
        return category_id ? (
          <span className="cap">{category_id?.name}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("Total Views"),
      dataIndex: "most_view",
      key: "most_view",
      render: (_, { most_view }) => {
        return most_view ? most_view : "0";
      },
    },

    {
      title: lang("Total search count"),
      dataIndex: "search_count",
      key: "search_count",
      render: (_, { search_count }) => {
        return search_count ? search_count : "0";
      },
    },
   
   
  ];

  return (
    <>
      <div className="tabled bannerMain">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{
                    defaultPageSize: 10,
                    responsive: true,
                    total: pagination,
                    // showSizeChanger: true,
                    // pageSizeOptions: ["10", "20", "30", "50"],
                  }}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Index;
